<div class="page-header">
  <!-- <h1 *ngIf="!filterEnable">{{ 'userManageMent' | translate }}</h1>
  <h1 *ngIf="filterEnable">{{ 'systemUsers' | translate }}</h1> -->

  <h1>
    @if (userData && userData.demographics) {
      {{ userData.demographics.firstName }} {{ userData.demographics.lastName }}
    } @else {
      {{ 'userManageMent' | translate }}
    }
  </h1>
  <!-- <h1 *ngIf="userData">{{ userData?.demographics.firstName }} {{ userData?.demographics.lastName }}</h1> -->

  <nav>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        @if (filterEnable) {
          <a [routerLink]="['/userManagment/list']">{{ 'userManageMent' | translate }}</a>
        }
        @if (!filterEnable) {
          <a [routerLink]="['/userManagment/list']" [queryParams]="{ clientId: clientID }">{{
            'userManageMent' | translate
          }}</a>
        }
      </li>
      @if (userData?.demographics) {
        <li class="breadcrumb-item active">
          {{ userData.demographics.firstName }} {{ userData.demographics.lastName }}
        </li>
      }
    </ol>
  </nav>
</div>

<div class="page-content-wrapper">
  <form [formGroup]="updateUser">
    <div class="row">
      <div class="col-md-6">
        <div class="user-status">
          <div class="user-status-preferences card-base">
            <div class="user-card-header flex-between">
              <h2>{{ 'titleProfile' | translate }}</h2>
              <div class="card-header-icons">
                <i-feather
                  *appPermissions="'self.Management_User.Update'"
                  name="edit-2"
                  (click)="editUserDetails()"
                ></i-feather>
              </div>
            </div>
            <div class="view-detail-row">
              <div class="view-detail-item">
                <strong class="view-detail-label">{{ 'userType' | translate }}: </strong>
                <div class="view-detail-info">{{ userData.userType | clientLabel }}</div>
              </div>
              <div class="view-detail-item">
                <strong class="view-detail-label">{{ 'status' | translate }}: </strong>
                <div class="view-detail-info">
                  {{ userData.status === 'InActive' ? 'Inactive' : 'Active' }}
                </div>
              </div>
              <div class="view-detail-item">
                <strong class="view-detail-label">{{ 'client' | translate }}: </strong>
                <div class="view-detail-info">{{ userData.client.name }}</div>
              </div>
              <div class="view-detail-item">
                <strong class="view-detail-label">{{ 'username' | translate }}:</strong>
                <div class="view-detail-info">{{ userData.userName }}</div>
              </div>
              <div class="view-detail-item">
                <strong class="view-detail-label">{{ 'firstName' | translate }}:</strong>
                <div class="view-detail-info">{{ userData.demographics.firstName }}</div>
              </div>
              <div class="view-detail-item">
                <strong class="view-detail-label">{{ 'lastName' | translate }}:</strong>
                <div class="view-detail-info">{{ userData.demographics.lastName }}</div>
              </div>
              <div class="view-detail-item">
                <strong class="view-detail-label">{{ 'gender' | translate }}:</strong>
                <div class="view-detail-info">
                  {{
                    userData.demographics.sex && userData.demographics.sex.toLowerCase() === 'unknown'
                      ? 'Prefer not to say'
                      : (userData.demographics.sex | translate)
                  }}
                </div>
              </div>
              <!-- <div class="view-detail-item" *ngIf="currentUserType==='Managed'">
              <strong class="view-detail-label">Manager:</strong> <div class="view-detail-info">{{managersList?.firstName}} {{managersList?.lastName}}</div>
            </div> -->
              @if (currentUserType === 'Managed') {
                <div class="view-detail-item">
                  <strong class="view-detail-label">{{ 'dateOfBirth' | translate }}:</strong>
                  <div class="view-detail-info">{{ userData.demographics.birthDate | date: 'mediumDate' }}</div>
                </div>
              }
              <div class="view-detail-item">
                <strong class="view-detail-label">{{ 'emailAddress' | translate }}:</strong>
                <div class="view-detail-info">{{ userData.demographics.emailAddress }}</div>
              </div>
              <div class="view-detail-item">
                <strong class="view-detail-label">{{ 'mobilePhone' | translate }}:</strong>
                <div class="view-detail-info">{{ userData.demographics.phone }}</div>
              </div>
              @if (currentUserType === 'Managed') {
                <div class="view-detail-item">
                  <strong class="view-detail-label">{{ 'primaryCondition' | translate }}:</strong>
                  <div class="view-detail-info">{{ userData.demographics.primaryDisease }}</div>
                </div>
              }

              <div class="view-detail-item">
                <strong class="view-detail-label">{{ 'timeZone' | translate }}:</strong>
                <div class="view-detail-info">{{ userData.demographics.timeZoneName }}</div>
              </div>

              <div class="view-detail-item">
                <strong class="view-detail-label">{{ 'externalID' | translate }}:</strong>
                @for (exId of userData.externalIds; track exId) {
                  <div class="view-detail-info">
                    <strong class="view-detail-label">{{ exId.k }}: </strong> {{ exId.v }}
                  </div>
                }
              </div>
            </div>
          </div>
        </div>

        <div class="user-status">
          <div class="user-status-preferences card-base">
            <div class="user-card-header flex-between">
              <h2>{{ 'UserGroups' | translate }}</h2>
              <div *appPermissions="'self.Management_User.Update'" class="card-header-icons">
                @if (!editUsersGroup) {
                  <i-feather name="edit-2" (click)="editUserGroup()"></i-feather>
                }
                @if (editUsersGroup) {
                  <i-feather name="check" (click)="saveUserGroup()"></i-feather>
                  <i-feather name="x" (click)="editUserGroup()"></i-feather>
                }
              </div>
            </div>

            @if (!editUsersGroup) {
              <div>
                <ng-container>
                  <!-- {{ groupListData | json }} -->
                  @for (item of groupListData; track item) {
                    <div class="user-group-view">
                      <span>{{ item?.name }}</span>
                      <p class="mb-0">
                        <strong class="ff-medium">Type:</strong><span class="ms-2">{{ item?.role | userGrpType }}</span>
                      </p>
                    </div>
                  }
                </ng-container>
                <!-- <div class="form-group form-check-flex" *ngFor="let group of groupListData; let i = index">
              <div class="form-check-inner">
                <label for="d1"> {{ group.groupName }}:{{ group.owner?.name }}</label>
              </div>
            </div> -->
                @if (groupListData.length === 0) {
                  <div class="form-group form-check-flex">
                    <div class="form-check-inner">
                      <div class="norecord">{{ 'noRecordFound' | translate }}</div>
                    </div>
                  </div>
                }
              </div>
            }
            <!-- <pre>{{groupList | json}}</pre> -->

            @if (editUsersGroup) {
              @if (supportGroup && supportGroup.length > 0) {
                <app-add-edit-user-group
                  [userGrpList]="supportGroup"
                  [selectedUserGrpList]="groupListData"
                  [userType]="userData.userType"
                  [submitted]="submittedUserGrp"
                  (filterUserGrpKey)="filterUserGrp($event)"
                  (searchKey)="searchUserGrp($event)"
                ></app-add-edit-user-group>
              }
            }

            <!-- <div class="checkbox-2-col" *ngIf="editUsersGroup && groupListData.length > 0">
        <div
          class="form-group form-check-flex"
          formArrayName="userGroup"
          *ngFor="let group of groupListData; let i = index"
          >
          <input
            type="checkbox"
            class="form-check-input"
            id="{{ group.group }}"
            [formControlName]="i"
            [value]="group.group"
            (change)="getSelectedGroup(group, groupListData, $event)"
            />

          <label for="{{ group.group }}">{{ group.groupName }}</label>
        </div>
      </div> -->
            @if (editUsersGroup && groupListData.length === 0) {
              <div>
                <div class="form-group form-check-flex">
                  <div class="form-check-inner">
                    <div class="norecord">{{ 'noRecordFound' | translate }}</div>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>

        <!-- <div class="user-status" *ngIf="currentUserType === 'Manager'">
  <div class="user-status-preferences card-base">
    <div class="user-card-header flex-between">
      <h2>{{ 'managingAndSupportGroups' | translate }}</h2>
      <div *appPermissions="'self.Management_User.Update'" class="card-header-icons">
        <i-feather name="edit-2" *ngIf="!editUserManaging" (click)="editManaging()"></i-feather>

        <i-feather name="X" *ngIf="editUserManaging" (click)="editManaging()"></i-feather>
      </div>
    </div>
    <div class="managed_support" *ngIf="!editUserManaging">
      <div class="form-group">
        <div class="title">
          <label
            >{{ userData?.demographics.firstName }} {{ userData?.demographics.lastName }}
            {{ 'manages' | translate }}
          </label>

        </div>

        <div>
          <mat-accordion
            class="example-headers-align"
            [togglePosition]="'before'"
            multi="true"
            *ngFor="let item of groupList?.owner; let i = index"
            >
            <mat-expansion-panel showToggle="true" [expanded]="true" class="horizontal_top_border active_group">
              <mat-expansion-panel-header class="managed_accordion">
                <div class="parent_title">
                  <div>{{ item.groupName }}</div>
                  <div class="center-align" (click)="$event.stopPropagation()">
                    <i-feather
                      name="users"
                      class="feather-20"
                      (click)="openDialogUser(item.group, groupList.member, 'Managed', item.groupName)"
                    ></i-feather>
                  </div>
                </div>
              </mat-expansion-panel-header>
              <ng-template matExpansionPanelContent>
                <mat-accordion class="example-headers-align" [togglePosition]="'before'" multi="false">
                  <mat-expansion-panel showToggle="true" [expanded]="expandSupport" #matExpansionPanel2>
                    <mat-expansion-panel-header
                      class="mt-2"
                      #panelH
                      (click)="expandPanel(matExpansionPanel2, $event); getGroupsList('coOwner', item.group, i)"
                      >
                      <div
                        class="parent_title"
                                (click)="
                                  expandPanel(matExpansionPanel2, $event); getGroupsList('coOwner', item.group, i)
                                "
                        >
                        <div>
                          <mat-panel-title> {{ 'support' | translate }}</mat-panel-title>
                        </div>
                      </div>
                    </mat-expansion-panel-header>

                    <ng-template matExpansionPanelContent>
                      <div class="sub_class" *ngFor="let step of groupList.owner[i].coOwnerList">
                        <div>{{ step?.name }}</div>
                      </div>
                    </ng-template>
                  </mat-expansion-panel>
                </mat-accordion>
              </ng-template>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
      <div class="form-group">
        <div class="title">
          <label class=""
            >{{ userData?.demographics?.firstName }} {{ userData?.demographics?.lastName }}
            {{ 'supports' | translate }}</label
            >

          </div>
          <div class="group_manage_wrap" *ngFor="let data of groupList?.coOwner">
            <div class="d-flex flex-start">
              <div class="row_manage">
                <div class="">{{ data.groupName }}</div>
                <div class="" (click)="$event.stopPropagation()">
                  <i-feather
                    name="users"
                    (click)="openDialogUser(data.group, groupList.member, 'co-owner', data.groupName)"
                    class="feather-20 pointer"
                  ></i-feather>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="managed_support" *ngIf="editUserManaging">
        <div class="form-group">
          <div class="title">
            <label
              >{{ userData?.demographics.firstName }} {{ userData?.demographics.lastName }}
              {{ 'manages' | translate }}
            </label>
          </div>
          <div class="pr-3">
            <mat-accordion
              class="example-headers-align"
              [togglePosition]="'before'"
              multi="true"
              *ngFor="let item of groupList?.owner; let i = index"
              >
              <mat-expansion-panel showToggle="true" [expanded]="true" class="horizontal_border active_group">
                <mat-expansion-panel-header class="managed_accordion">
                  <div class="parent_title">
                    <div class="">{{ item.groupName }}</div>
                    <div class="center-align" (click)="$event.stopPropagation()">
                      <i-feather
                        name="users"
                        (click)="openDialogUser(item.group, groupList.member, 'Managed', item.groupName)"
                        class="feather-20"
                      ></i-feather>
                    </div>
                  </div>
                </mat-expansion-panel-header>
                <ng-template matExpansionPanelContent>
                  <mat-accordion class="example-headers-align" [togglePosition]="'before'" multi="false">
                    <mat-expansion-panel [expanded]="expandSupport" #matExpansionPanel1>
                      <mat-expansion-panel-header
                        class="managed_accordion mt-2"
                        (click)="expandPanel(matExpansionPanel1, $event); getGroupsList('coOwner', item.group, i)"
                        >
                        <div
                          class="parent_title"
                                (click)="
                                  expandPanel(matExpansionPanel1, $event); getGroupsList('coOwner', item.group, i)
                                "
                          >
                          <div class="">
                            <mat-panel-title> {{ 'support' | translate }} </mat-panel-title>
                          </div>
                          <div class="" (click)="$event.stopPropagation()">
                            <i-feather
                              name="plus"
                                    (click)="
                                      getGroupsList('coOwner', item.group, i); dialogForCoOwner(item.group, item, i)
                                    "
                              class="feather-20 pointer"
                            ></i-feather>
                          </div>
                        </div>
                      </mat-expansion-panel-header>
                      <ng-template matExpansionPanelContent>

                        <div *ngFor="let step of groupList.owner[i].coOwnerList" class="sub_class">
                          <div>{{ step?.name }}</div>
                          <div (click)="$event.stopPropagation()">
                            <i-feather
                              *appPermissions="'self.Management_User.Delete'"
                              name="x"
                              class="feather-20 pointer"
                              (click)="deleteCoOwnerInUserGroup(item.group, step.user, 'support', i)"
                            ></i-feather>
                          </div>
                        </div>
                      </ng-template>
                    </mat-expansion-panel>
                  </mat-accordion>
                </ng-template>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>
        <div class="form-group">
          <div class="title">
            <label class=""
              >{{ userData?.demographics.firstName }} {{ userData?.demographics.lastName }}
              {{ 'supports' | translate }}</label
              >
            </div>
            <div class="group_manage_wrap">
              <div class="row_manage pointer" (click)="openDialog(groupList?.coOwner)">
                <span>
                  <i-feather name="plus" class="feather-20"></i-feather>
                </span>
                <label> {{ 'addGroup' | translate }}</label>
              </div>
            </div>

            <div class="group_manage_wrap" *ngFor="let item of groupList?.coOwner">
              <div class="d-flex flex-start">
                <div class="row_manage">

                  <label>{{ item.groupName }}</label>
                </div>
                <span class="ic_group">
                  <i-feather
                    name="users"
                    (click)="openDialogUser(item.group, groupList.member, 'co-owner', item.groupName)"
                    class="feather-20"
                  ></i-feather>
                </span>

                <span class="ic_group">
                  <i-feather
                    name="x"
                    *appPermissions="'self.Management_User.Delete'"
                    class="feather-20"
                    (click)="deleteCoOwnerInUserGroup(item.group, uid)"
                  ></i-feather>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
      </div>
      <div class="col-md-6">
        @if (!roleDashboardEdit) {
          <div class="u-right-col">
            <div class="user-status">
              <div class="user-status-preferences card-base">
                <div class="user-card-header flex-between">
                  <h2>{{ 'roles' | translate }}*</h2>
                  <div *appPermissions="'self.Management_User.Update'" class="card-header-icons">
                    <!-- <i-feather name="check" *ngIf="enableDisableEdit"></i-feather> -->
                    @if (!enableDisableEdit) {
                      <i-feather name="edit-2" (click)="enableEdit()"></i-feather>
                    }
                    @if (enableDisableEdit) {
                      <i-feather name="X" (click)="enableEdit()"></i-feather>
                    }
                  </div>
                </div>
                @if (!enableDisableEdit) {
                  <div class="checkbox-2-col">
                    @for (role of userData.roles; track role; let i = $index) {
                      <div class="form-group form-check-flex">
                        <label for="{{ role.id }}">{{ role.label }}</label>
                      </div>
                    }
                  </div>
                }
                @if (enableDisableEdit) {
                  <div class="checkbox-2-col">
                    @for (role of roleList; track role; let i = $index) {
                      <div class="form-group form-check-flex" formArrayName="roles">
                        <input
                          type="checkbox"
                          class="form-check-input"
                          id="{{ role._id }}"
                          [formControlName]="i"
                          [value]="role._id"
                          (change)="getSelected(role, $event)"
                        />
                        <label for="{{ role._id }}">{{ role.label }}</label>
                      </div>
                    }
                  </div>
                }
                @if (roleError && selectedRoles.length === 0) {
                  <div class="error mt-24">
                    {{ roleError }}
                  </div>
                }
              </div>
            </div>
            @if (currentUserType === 'Manager') {
              <div class="user-status">
                <div class="user-status-preferences card-base">
                  <div class="user-card-header flex-between">
                    <h2>{{ 'dashboards' | translate }}</h2>
                    <div *appPermissions="'self.Management_User.Update'" class="card-header-icons">
                      @if (!editDashboard) {
                        <i-feather name="edit-2" (click)="dashboardEdit()"></i-feather>
                      }
                      @if (editDashboard && dashboardList.length > 0) {
                        <i-feather name="check" (click)="updateDashboard()"></i-feather>
                        <i-feather name="x" (click)="dashboardEdit()"></i-feather>
                      }
                    </div>
                  </div>
                  @if (!editDashboard) {
                    <div class="checkbox-col d-flex flex-wrap">
                      <div class="form-group form-check">
                        <div class="form-check-inner">
                          <label> {{ userData?.defaultDashboard?.dashboardTitle }} </label>
                        </div>
                      </div>
                    </div>
                  }
                  @if (editDashboard && dashboardList.length > 0) {
                    <div>
                      <div class="d-flex justify-content-between mx-2 px-1 mb-2">
                        <h2 class="mb-3 pb-1"></h2>
                        <label class="dash-default">{{ 'setDefault' | translate }}</label>
                      </div>
                      @for (dashboard of dashboardList; track dashboard; let i = $index) {
                        <div class="checkbox-col form-group pt-1" formArrayName="dashboards">
                          <div class="form-group form-check-flex">
                            <!-- <input type="checkbox" class="form-check-input" id="d1"  id="{{dashboard._id}}" [formControlName]="i" [value]="dashboard._id" (change)="getSelectedDashboard(dashboard._id, $event)"/> -->
                            <label for="d1"> {{ dashboard?.dashboardTitle }}</label>
                            <input
                              class="pointer form-check-input"
                              type="radio"
                              [checked]="dashboard.id === getDefaultDashboardValue.id ? true : false"
                              value="{{ dashboard.id }}"
                              (change)="getDefaultDashboard(dashboard)"
                              name="i"
                            />
                          </div>
                        </div>
                      }
                    </div>
                  }
                </div>
              </div>
            }
          </div>
        }
      </div>
    </div>
  </form>
</div>
