import { ChartTypes } from './chart-types';
import {
  CHART_DATE_FORMAT,
  CHART_TIME_FORMAT,
  CHART_TIME_SECOND_FORMAT,
  DEFAULT_AREA_RANGE_COLOR,
  DEFAULT_CHART_HEIGHT,
  DEFAULT_COLOR,
} from './charts.constant';

/**
 * Default options configuration for a chart.
 */
export const DEFAULT_CHART_OPTIONS = {
  accessibility: {
    enabled: false,
  },
  chart: {
    height: DEFAULT_CHART_HEIGHT,
    spacing: [15, 10, 15, 10],
    backgroundColor: 'transparent',
    events: {
      selection: undefined,
    },
  },
  exporting: {
    enabled: false,
  },
  credits: {
    enabled: false,
  },
  scrollbar: {
    enabled: false,
  },
  navigator: {
    enabled: false,
    adaptToUpdatedData: false,
  },
  rangeSelector: {
    enabled: false,
  },
  series: [],
};

/**
 * Options configuration specifically for pie charts, including tooltip and legend settings.
 */
export const PIE_CHART_OPTIONS = {
  accessibility: {
    enabled: false,
  },
  chart: {
    backgroundColor: 'transparent',
  },
  exporting: {
    enabled: false,
  },
  credits: {
    enabled: false,
  },
  scrollbar: {
    enabled: false,
  },
  navigator: {
    enabled: false,
    adaptToUpdatedData: false,
  },
  rangeSelector: {
    enabled: false,
  },
  tooltip: {
    shared: true,
    useHTML: true,
    style: {
      color: '#E9ECEF',
      fontSize: '11px',
      padding: '0',
    },
    backgroundColor: '#2c2c2c',
    shadow: false,
    borderWidth: 0,
    headerFormat: '',
    pointFormat:
      '<p style="font-size:12px;margin:0px;padding-top:1px;padding-bottom:1px;">{point.name} : <strong>{point.y}</strong></p>',
  },
  legend: {
    enabled: true,
    useHTML: true,
    layout: 'vertical',
    align: 'right',
    verticalAlign: 'middle',
    itemMarginTop: 5,
    itemMarginBottom: 5,
  },
  plotOptions: {
    pie: {
      dataLabels: {
        enabled: true,
        format: '{point.y}',
        distance: 5,
        filter: {
          property: 'y',
          operator: '>',
          value: 0,
        },
      },
      showInLegend: true,
    },
    series: [],
  },
};

/**
 * Options configuration specifically for bar charts, including tooltip and axis settings.
 */
export const BAR_CHART_OPTIONS = {
  tooltip: {
    shared: true,
    useHTML: true,
    style: {
      color: '#E9ECEF',
      fontSize: '11px',
      padding: '0',
    },
    backgroundColor: '#2c2c2c',
    shadow: false,
    borderWidth: 0,
    headerFormat: '',
    pointFormat:
      '<p style="font-size:12px;margin:0px;padding-top:1px;padding-bottom:1px;">{point.name} : <strong>{point.y}</strong></p>',
  },
  xAxis: {
    type: 'category',
    uniqueNames: false,
    title: {
      text: null,
    },
    labels: {
      enabled: false,
      align: 'left',
      reserveSpace: true,
    },
  },
  yAxis: {
    opposite: false,
    allowDecimals: false,
    lineWidth: 1,
    title: false,
  },
  plotOptions: {
    bar: {
      dataLabels: {
        enabled: true,
      },
    },
  },
};

/**
 * datetime options configuration for a chart.
 */
export const DATE_TIME_CHART_OPTIONS = {
  accessibility: {
    enabled: false,
  },
  chart: {
    height: DEFAULT_CHART_HEIGHT,
    spacing: [15, 10, 15, 10],
    backgroundColor: 'transparent',
    events: {
      selection: undefined,
    },
    panning: {
      enabled: false,
      type: 'x',
    },
    zooming: {
      enabled: false,
      type: undefined,
      mouseWheel: {
        enabled: false,
      },
    },
    zoomType: 'None',
  },
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
  scrollbar: {
    enabled: false,
  },
  navigator: {
    enabled: false,
    adaptToUpdatedData: false,
  },
  rangeSelector: {
    enabled: false,
  },
  legend: {
    enabled: false,
    useHTML: true,
    symbolHeight: 8,
    symbolWidth: 8,
    symbolRadius: 4,
    padding: 8,
    itemDistance: 4,
    squareSymbol: false,
    itemStyle: {
      fontWeight: 'normal',
      fontSize: '12px',
      textTransform: 'capitalize',
    },
  },
  tooltip: {
    enabled: true,
    useHTML: true,
    outside: true,
    split: false,
    shared: false,
    backgroundColor: '#2c2c2c',
    borderWidth: 0,
    shadow: false,
    shape: 'square',
    style: {
      color: '#E9ECEF',
      fontSize: '12px',
      padding: 0,
    },
  },
  title: {
    text: '',
  },
  xAxis: {
    type: 'datetime',
    dateTimeLabelFormats: {
      second: CHART_TIME_SECOND_FORMAT,
      minute: CHART_TIME_FORMAT,
      hour: CHART_TIME_FORMAT,
      day: CHART_DATE_FORMAT,
      week: CHART_DATE_FORMAT,
      millisecond: CHART_TIME_SECOND_FORMAT,
    },
    title: {
      text: '',
    },
    showLastLabel: true,
    startOnTick: true,
    endOnTick: true,
    ordinal: false,
    minTickInterval: 1000 * 60,
    minRange: 1000 * 60,
    labels: {
      rotation: -45,
      enabled: true,
      style: {
        fontSize: '8px',
        fontWeight: 'bold',
        color: '#b4b4b4',
      },
    },
    crosshair: false,
    gridLineWidth: 0,
  },
  yAxis: {
    opposite: false,
    title: {
      text: '',
    },
    labels: {
      enabled: true,
    },
    crosshair: false,
    startOnTick: true,
    endOnTick: true,
    gridLineWidth: 1,
    allowDecimals: false,
    showLastLabel: true,
  },
  series: [],
};

/**
 * Default series options for charts, including type and marker settings.
 */
export const DEFAULT_SERIES_OPTIONS = {
  type: ChartTypes.LINE,
  lineWidth: 2,
  marker: {
    enabled: true,
    symbol: 'circle',
    radius: 3,
    states: {
      hover: {
        radius: 6,
      },
    },
  },
};

/**
 * Column series options for charts, including width and color settings.
 */
export const COLUMN_SERIES_OPTIONS = {
  inverted: false,
  lineWidth: 2,
  maxPointWidth: 10,
  pointWidth: 10,
  linkedTo: ':previous',
  color: DEFAULT_COLOR,
  fillOpacity: 0.3,
  zIndex: 0,
  marker: {
    enabled: true,
  },
};

/**
 * Bar series options for charts, including width and color settings.
 */
export const BAR_SERIES_OPTIONS = {
  lineWidth: 2,
  maxPointWidth: 30,
  pointWidth: 30,
  color: DEFAULT_COLOR,
  fillOpacity: 0.3,
  zIndex: 0,
  marker: {
    enabled: true,
  },
};

/**
 * Area series options for charts, including type, opacity, and tracking settings.
 */
export const AREA_SERIES_OPTIONS = {
  type: ChartTypes.AREA_SPLINE_RANGE,
  lineWidth: 1,
  fillOpacity: 0.3,
  zIndex: 0,
  color: DEFAULT_AREA_RANGE_COLOR,
  marker: {
    enabled: false,
  },
  zoneAxis: 'y',
  enableMouseTracking: false,
};

/**
 * Bubble series options for charts, including size and hover states.
 */
export const BUBBLE_SERIES_OPTIONS = {
  minSize: 8,
  maxSize: 8,
  marker: {
    symbol: 'circle',
    fillOpacity: 1,
    radius: 3,
  },
  states: {
    hover: {
      opacity: 1,
      size: 3,
    },
  },
};

/**
 * Pie series options for charts, including color settings for each point.
 */
export const PIE_SERIES_OPTIONS = {
  colorByPoint: true,
};
