import { HttpEvent } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import {
  AddParticipantsRequest,
  ChatClient,
  ChatMessage,
  ChatParticipant,
  ChatThreadClient,
  CreateChatThreadOptions,
  CreateChatThreadRequest,
  CreateChatThreadResult,
  SendChatMessageResult,
  SendMessageOptions,
  SendMessageRequest,
  SendTypingNotificationOptions,
  TypingIndicatorReceivedEvent,
  UpdateMessageOptions,
} from '@azure/communication-chat';
import { CommunicationUserIdentifier } from '@azure/communication-common';
import { firstValueFrom, Observable, retry, Subject } from 'rxjs';
import { DemographicData, FileUploadSuccess } from 'src/app/modules/shared/interfaces/common.entities';
import { Contact } from 'src/app/modules/shared/interfaces/contact.entities';
import { ACSService } from 'src/app/modules/shared/services/acs.service';
import { ContactsService } from 'src/app/modules/shared/services/contacts.service';
import { FileUploadService } from 'src/app/modules/shared/services/file-upload.service';
import { HttpService } from 'src/app/modules/shared/services/http.service';
import { environment } from 'src/environments/environment';
import {
  APISuccess,
  Attachment,
  ChatFilter,
  MessageTemplateReplacementKeys,
  MultipleFileUploadSuccess,
  PaginatedChatThreadMetaData,
  RecalledMessageData,
  TemporaryFileUrl,
  UserMetaData,
} from '../entities/message.entities';
/**
 * Service for managing chat messages and threads.
 */
@Injectable({
  providedIn: 'root',
})
export class MessageService {
  /**
   * The communication user ID used for communication purposes.
   */
  public communicationUserId = '';
  /**
   * Category of files attached to messages.
   */
  public attachmentFileCategory = 'messages';
  /**
   * Number of messages to fetch per thread page.
   */
  public threadPageSize = 20;
  /**
   * Maximum allowed file size for attachments, in bytes.
   */
  public maxFileSizeAllowed = 27262976;
  /**
   * Maximum number of files allowed to be attached in a message.
   */
  public maxNumberOfFilesAllowed = 10;
  /**
   * Display name of the user.
   */
  public displayName!: string;
  /**
   * Demographic data of the user.
   */
  public userDemographicData!: DemographicData;
  /**
   * Subject for closing message dialogs.
   */
  public closedMessageDialog$: Subject<boolean> = new Subject();
  /**
   * Configuration for CKEditor used in message composition.
   */
  public ckEditorConfig = {
    /**
     * Toolbar configuration for CKEditor.
     * This array defines which buttons are visible in the CKEditor toolbar.
     */
    toolbar: [],
    /**
     * List of CKEditor plugins to be removed from the editor.
     * These plugins will not be available for use in the CKEditor instance.
     */
    removePlugins: [
      'Bold',
      'Italic',
      'BlockQuote',
      'EasyImage',
      'Heading',
      'Image',
      'ImageCaption',
      'ImageStyle',
      'ImageToolbar',
      'ImageUpload',
      'CKFinder',
      'CKBox',
      'PictureEditing',
      'TableToolbar',
      'CloudServices',
      'MediaEmbed',
      'Table',
      'Indent',
      'List',
      'CKFinderUploadAdapter',
    ],
    /**
     * Placeholder text displayed in the CKEditor when it's empty.
     * Users are prompted to write a reply in the CKEditor with this placeholder.
     */
    placeholder: 'Write a reply',
  };

  /**
   * Observable for receiving typing indicator events in the chat.
   * Emits `TypingIndicatorReceivedEvent` when someone is typing.
   */
  public typing: Observable<TypingIndicatorReceivedEvent> = new Observable((subscriber) => {
    this.chatClient.on('typingIndicatorReceived', (indication: TypingIndicatorReceivedEvent): void => {
      if ((indication.sender as CommunicationUserIdentifier).communicationUserId !== this.communicationUserId) {
        subscriber.next(indication);
      }
    });
  });

  /**
   * Base URL for API requests.
   */
  private baseUrl: string = environment.url.apiHost;
  /**
   * API version for API requests.
   */
  private apiVersion: string = environment.url.version;
  /**
   * Azure Communication Services chat client.
   */
  private chatClient!: ChatClient;
  /**
   * Array to store selected thread messages.
   */
  private selectedThreadMessages: ChatMessage[] = [];

  /**
   * Service for managing contacts.
   */
  #contactsService = inject(ContactsService);
  /**
   * Service for sanitizing URLs.
   */
  #sanitize = inject(DomSanitizer);
  /**
   * Service for uploading files.
   */
  #fileUploadService = inject(FileUploadService);
  /**
   * Constructs the `MessageService` with required services.
   * @param acsService - Service for Azure Communication Services.
   * @param http - HTTP service for making API requests.
   */
  constructor(
    private acsService: ACSService,
    private http: HttpService,
  ) {}

  /**
   * Initializes the chat service.
   * @returns Promise that resolves when initialization is complete.
   */
  public async initChat(): Promise<void> {
    this.userDemographicData = JSON.parse(localStorage.getItem('demographicData') || '{}');

    this.displayName = `${this.userDemographicData?.firstName} ${this.userDemographicData.lastName}`;
    await this.acsService.initChat();
    this.chatClient = this.acsService.chatClient || ({} as ChatClient);
    this.communicationUserId = await this.acsService.getACSToken();
  }

  /**
   * Creates a new chat thread.
   * @param topic - The topic of the new chat thread.
   * @param participants - Array of participants to add to the thread.
   * @returns Promise that resolves with the result of creating the chat thread.
   */
  public createChatThread(topic: string, participants: ChatParticipant[]): Promise<CreateChatThreadResult> {
    const createChatThreadRequest: CreateChatThreadRequest = {
      topic,
    };
    const createChatThreadOptions: CreateChatThreadOptions = {
      participants: [
        {
          id: { communicationUserId: this.communicationUserId },
          displayName: this.displayName,
        },
        ...participants,
      ],
    };
    return this.chatClient.createChatThread(createChatThreadRequest, createChatThreadOptions);
  }

  /**
   * Retrieves chat threads based on specified filters.
   * @param filters - Filters to apply for querying chat threads.
   * @returns A promise resolving to PaginatedChatThreadMetaData.
   */
  public getMyMessageThreads(filters: ChatFilter): Promise<PaginatedChatThreadMetaData> {
    let mainFilterString = filters.subject ? `?search=subject|${filters.subject}` : '';
    mainFilterString += filters.message
      ? mainFilterString
        ? `,message|${filters.message}`
        : `?search=message|${filters.message}`
      : '';

    mainFilterString += filters.startDate
      ? mainFilterString
        ? `&startDate=${filters.startDate.toISOString()}`
        : `?startDate=${filters.startDate.toISOString()}`
      : '';

    mainFilterString += filters.endDate
      ? mainFilterString
        ? `&endDate=${filters.endDate.toISOString()}`
        : `?endDate=${filters.endDate.toISOString()}`
      : '';

    let filterString = filters.user ? `filter=user|${filters.user}` : '';
    filterString += filters.unread ? (filterString ? `,unread|true` : `filter=unread|true`) : '';
    filterString += filters.to ? (filterString ? `,to|${filters.to.user}` : `filter=to|${filters.to.user}`) : '';
    filterString += filters.from
      ? filterString
        ? `,from|${filters.from.user}`
        : `filter=from|${filters.from.user}`
      : '';

    mainFilterString += filterString ? (mainFilterString ? `&${filterString}` : `?${filterString}`) : '';
    mainFilterString += mainFilterString ? `&page=${filters.page}` : `?page=${filters.page}`;

    const url = `${this.baseUrl}${this.apiVersion}/chat/metadata${mainFilterString}`;
    return firstValueFrom(this.http.get(url, {}, true));
  }
  /**
   * Retrieves team-related chat threads based on specified filters.
   * @param filters - Filters to apply for querying team chat threads.
   * @returns A promise resolving to PaginatedChatThreadMetaData.
   */
  public getMyTeamMessageThreads(filters: ChatFilter): Promise<PaginatedChatThreadMetaData> {
    const filterString: string = this.getFilterString(filters);
    const url = `${this.baseUrl}${this.apiVersion}/chat/metadata/myteams${filterString}`;
    return firstValueFrom(this.http.get(url, {}, true));
  }
  /**
   * Sends a message to a specific chat thread.
   * @param threadId - The ID of the chat thread.
   * @param message - The content of the message to send.
   * @param attachments - Array of attachments to include with the message.
   * @returns A promise resolving to SendChatMessageResult.
   */
  public sendMessage(threadId: string, message: string, attachments: Attachment[]): Promise<SendChatMessageResult> {
    const chatThreadClient: ChatThreadClient = this.chatClient.getChatThreadClient(threadId);
    const sendMessageRequest: SendMessageRequest = {
      content: message,
    };

    const attachmentsClone: Attachment[] = JSON.parse(JSON.stringify(attachments));
    attachmentsClone.forEach((attachment: Attachment): void => {
      attachment.usersFeedback = [];
      delete attachment.fileIcon;
      delete attachment.file;
      delete attachment.uploadProgress;
    });
    const sendMessageOptions: SendMessageOptions = {
      senderDisplayName: this.displayName,
      type: 'text',
      metadata: {
        attachments: JSON.stringify(attachmentsClone),
        recallDate: '',
        alerts: JSON.stringify([]),
        recommendedResources: JSON.stringify([]),
        readBy: JSON.stringify([]),
        intent: JSON.stringify([]),
        keyword: JSON.stringify([]),
      },
    };

    return chatThreadClient.sendMessage(sendMessageRequest, sendMessageOptions);
  }
  /**
   * Retrieves a list of participants in a specific chat thread.
   * @param threadId - The ID of the chat thread.
   * @returns A promise resolving to an array of ChatParticipant.
   */
  public async getParticipantList(threadId: string): Promise<ChatParticipant[]> {
    const participantsIterator = this.chatClient.getChatThreadClient(threadId).listParticipants();
    const participants: ChatParticipant[] = [];
    for await (const participant of participantsIterator) {
      participant.displayName =
        this.#contactsService.getUserFromACSId((participant.id as CommunicationUserIdentifier).communicationUserId)
          ?.userFullName || '';
      participants.push(participant);
    }
    return participants;
  }

  /**
   * Adds participants to a specific chat thread.
   * @param threadId - The ID of the chat thread.
   * @param participants - Array of participants to add.
   * @returns A promise that resolves when participants are added.
   */
  public async addParticipants(threadId: string, participants: ChatParticipant[]): Promise<void> {
    const addParticipantRequest: AddParticipantsRequest = {
      participants,
    };
    await this.chatClient.getChatThreadClient(threadId).addParticipants(addParticipantRequest);
  }

  /**
   * Removes participants from a specific chat thread.
   * @param threadId - The ID of the chat thread.
   * @param participants - Array of participants to remove.
   * @returns A promise that resolves when participants are removed.
   */
  public async removeParticipants(threadId: string, paticipants: ChatParticipant[]): Promise<void> {
    paticipants.forEach(async (paticipant: ChatParticipant): Promise<void> => {
      await this.chatClient.getChatThreadClient(threadId).removeParticipant(paticipant.id);
    });
  }

  /**
   * Retrieves all messages for a specific chat thread owned by the current user.
   * @param threadId - The ID of the chat thread.
   * @returns A promise resolving to an array of ChatMessage.
   */
  public async getAllMessagesForMyThreads(threadId: string): Promise<ChatMessage[]> {
    // Implement pagination
    this.selectedThreadMessages = [];
    const messagesIterator = this.chatClient.getChatThreadClient(threadId).listMessages();
    for await (const message of messagesIterator) {
      if (message.type === 'text') {
        this.selectedThreadMessages.push(message);
      }
    }
    return this.selectedThreadMessages;
  }

  /**
   * Retrieves all messages for a specific team-related chat thread owned by the current user.
   * @param threadId - The ID of the team-related chat thread.
   * @returns A promise resolving to an array of ChatMessage.
   */
  public async getAllMessagesForMyTeamsThreads(threadId: string): Promise<ChatMessage[]> {
    this.selectedThreadMessages = [];
    let page = 1;
    this.http.loader.next(true);
    while (true) {
      const url = `${this.baseUrl}${this.apiVersion}/chat/metadata/myteams/thread/${threadId}/messages`;
      const paginatedMessageData = await firstValueFrom(this.http.get(url, {}, true));
      this.selectedThreadMessages.push(...paginatedMessageData.data);
      if (paginatedMessageData.pagination.totalPage === page || paginatedMessageData.pagination.totalPage === 0) break;
      page++;
    }
    this.http.loader.next(false);
    return this.selectedThreadMessages;
  }

  /**
   * Marks specified messages as read in a chat thread.
   * @param threadId - The ID of the chat thread.
   * @param messageIds - Array of message IDs to mark as read.
   * @returns A promise that resolves when messages are marked as read.
   */
  public async markAsRead(threadId: string, messageIds: string[]): Promise<void> {
    const url = `${this.baseUrl}${this.apiVersion}/chat/${threadId}`;
    const chunkSize = 20;
    for (let i = 0; i < messageIds.length; i += chunkSize) {
      return await firstValueFrom(
        this.http.patch(
          url,
          {
            messageIds: messageIds.slice(i, i + chunkSize),
            readBy: true,
          },
          {},
          true,
        ),
      );
    }
  }

  /**
   * Recalls a message in a specific chat thread.
   * @param threadId - The ID of the chat thread.
   * @param messageId - The ID of the message to recall.
   * @param metadata - Additional metadata for updating the message.
   * @returns A promise that resolves when the message is recalled.
   */
  public recallMessage(threadId: string, messageId: string, metadata: Record<string, string>): Promise<void> {
    const updateMessageOptions: UpdateMessageOptions = {
      content: '',
      metadata,
    };
    return this.chatClient.getChatThreadClient(threadId).updateMessage(messageId, updateMessageOptions);
  }

  /**
   * Retrieves the icon URL or SafeUrl for a given file type and URL.
   * @param fileType - The MIME type of the file.
   * @param fileUrl - The URL of the file.
   * @returns The URL of the file icon or null if no matching icon found.
   */
  public getFileIcon(fileType: string, fileUrl: string): string | SafeUrl | null {
    if (fileType === 'text/csv' || fileType === 'text/plain') return 'assets/images/icons/doc.png';
    if (fileType === 'image/heif') return '/assets/images/icons/image.svg';

    const [type, subtype] = fileType.split('/');
    switch (type) {
      case 'image':
        return this.#sanitize.bypassSecurityTrustUrl(fileUrl);
      case 'audio':
        return '/assets/images/icons/audio.png';
      case 'video':
        return '/assets/images/icons/video.png';
      case 'application':
        return this.checkFileSubTypeForApplicationType(subtype);
      default:
        return null;
    }
  }

  /**
   * Uploads a single file to a specific chat thread.
   * @param file - The file to upload.
   * @param threadId - The ID of the chat thread.
   * @returns An observable that emits HttpEvent<FileUploadSuccess> events.
   */
  public uploadFile(file: File, threadId: string): Observable<HttpEvent<FileUploadSuccess>> {
    const formData = new FormData();
    formData.append('file', file);
    return this.#fileUploadService.uploadFile(
      formData,
      this.attachmentFileCategory,
      {
        reportProgress: true,
        observe: 'events',
      },
      true,
      `&threadId=${threadId}`,
    );
  }
  /**
   * Uploads multiple files to a specific chat thread.
   * @param files - Array of files to upload.
   * @param threadId - The ID of the chat thread.
   * @returns A promise resolving to MultipleFileUploadSuccess.
   */
  public uploadMultipleFiles(files: File[], threadId: string): Promise<MultipleFileUploadSuccess> {
    const formData = new FormData();
    files.forEach((file: File): void => {
      formData.append('file', file);
    });
    return firstValueFrom(
      this.#fileUploadService.uploadMultipleFiles(
        formData,
        this.attachmentFileCategory,
        {},
        true,
        `&threadId=${threadId}`,
      ),
    ) as Promise<MultipleFileUploadSuccess>;
  }
  /**
   * Retrieves a temporary URL for downloading a file attachment.
   * @param fileUrl - The URL of the file attachment.
   * @param threadId - The ID of the chat thread.
   * @returns A promise resolving to TemporaryFileUrl.
   */
  public async getTemporaryFileUrl(fileUrl: string, threadId: string): Promise<TemporaryFileUrl> {
    const url = `${this.baseUrl}${this.apiVersion}/chat/${threadId}/attachment/${fileUrl}`;
    return firstValueFrom(this.http.get(url, {}));
  }
  /**
   * Marks or unmarks a message as favorite in a chat thread.
   * @param threadId - The ID of the chat thread.
   * @param messageId - The ID of the message to mark as favorite.
   * @param favorite - Boolean indicating whether to mark as favorite (true) or not (false).
   * @param name - Name of the attachment.
   * @param url - URL of the attachment.
   * @returns A promise resolving to APISuccess when the operation completes.
   */
  public async markAsFavourite(
    threadId: string,
    messageId: string,
    favorite: boolean,
    name: string,
    url: string,
  ): Promise<APISuccess> {
    const apiUrl = `${this.baseUrl}${this.apiVersion}/chat/${threadId}`;
    return firstValueFrom(
      this.http.patch(
        apiUrl,
        {
          favorite,
          attachment: true,
          name,
          url,
          messageId,
        },
        {},
        true,
      ),
    );
  }
  /**
   * Sends a typing notification to indicate the current user is typing.
   * @param threadId - The ID of the chat thread.
   * @returns A promise that resolves when the typing notification is sent.
   */
  public async sendTypingNotification(threadId: string): Promise<void> {
    const typingNotificationOptions: SendTypingNotificationOptions = {
      senderDisplayName: this.displayName,
    };
    await this.chatClient.getChatThreadClient(threadId).sendTypingNotification(typingNotificationOptions);
  }

  /**
   * Get Recalled Message Data
   * @param messageId - Message Id of Recallled Message
   * @returns - Promise of Recalled Message API call
   */
  public async getRecalledMessage(messageId: string): Promise<RecalledMessageData> {
    const url = `${this.baseUrl}${this.apiVersion}/chat/message/${messageId}`;
    return firstValueFrom(this.http.get(url, {}, false));
  }

  /**
   * Delete Message Attachment from Server Storage
   * @param documentId - Id of attachment to be deleted
   * @param threadId - Thread Id
   */
  public deleteAttachmentFromServer(documentId: string, threadId: string): void {
    if (!documentId) return;
    const url = `${this.baseUrl}${this.apiVersion}/chat/${threadId}/document/${documentId}`;
    this.http.delete(url, {}, true).pipe(retry(3)).subscribe();
  }

  /**
   * Add Sender and Reciver Details to message
   * @param message - Chat Message
   * @param threadId - Thread Id required to fetch participants
   * @returns - Modified Chat Message
   */
  public async addTemplateData(message: string, threadId: string): Promise<string> {
    this.http.loader.next(true);
    const participants: ChatParticipant[] = await this.getParticipantList(threadId);

    let participant: Contact | null = null;
    for (let i = 0; i < participants.length; i++) {
      if ((participants[i].id as CommunicationUserIdentifier).communicationUserId === this.communicationUserId)
        continue;

      participant = this.#contactsService.getUserFromACSId(
        (participants[i].id as CommunicationUserIdentifier).communicationUserId,
      );
      if (!participant) continue;

      if (participant.userType === 'Managed') break;
    }

    const receiverName = participant?.userFullName.split(' ');
    message = message.replaceAll(
      MessageTemplateReplacementKeys.RECEIVERFIRSTNAME,
      receiverName?.slice(0, -1).join(' ') || MessageTemplateReplacementKeys.RECEIVERFIRSTNAME,
    );
    message = message.replaceAll(
      MessageTemplateReplacementKeys.PATIENTFIRSTNAME,
      receiverName?.slice(0, -1).join(' ') || MessageTemplateReplacementKeys.RECEIVERFIRSTNAME,
    );
    message = message.replaceAll(
      MessageTemplateReplacementKeys.RECEIVERLASTNAME,
      receiverName?.at(-1) || MessageTemplateReplacementKeys.RECEIVERLASTNAME,
    );
    message = message.replaceAll(
      MessageTemplateReplacementKeys.SENDERFIRSTNAME,
      this.userDemographicData?.firstName || MessageTemplateReplacementKeys.SENDERFIRSTNAME,
    );
    message = message.replaceAll(
      MessageTemplateReplacementKeys.SENDERLASTNAME,
      this.userDemographicData.lastName || MessageTemplateReplacementKeys.SENDERLASTNAME,
    );
    message = message.replaceAll(
      MessageTemplateReplacementKeys.WORKPHONE,
      this.userDemographicData.workPhone || MessageTemplateReplacementKeys.WORKPHONE,
    );

    this.http.loader.next(false);
    return message;
  }

  /**
   * Converts HTML formatted message to plain text.
   * @param htmlMessage - The HTML formatted message to convert.
   * @returns Plain text representation of the HTML message.
   */
  public ckEditorHtml2Text(htmlMessage: string): string {
    const dom = new DOMParser().parseFromString(htmlMessage, 'text/html');
    let message = '';

    dom.body.childNodes.forEach((child: ChildNode): void => {
      const c = child.childNodes;
      if (c.length === 1 && c[0].nodeType === 3) {
        message = `${message}${c[0].nodeValue}`;
      } else if (c.length === 1 && c[0].nodeName === 'A') {
        message = `${message}${this.checkForUrlAndModifyMessage(c[0])}`;
      } else {
        c.forEach((d: ChildNode) => {
          if (d.nodeName === 'A') {
            message = `${message}${this.checkForUrlAndModifyMessage(d)}`;
          } else if (d.nodeType === 1) {
            message += '\n';
          } else {
            message = `${message}${d.nodeValue}`;
          }
        });
      }
      message += '\n';
    });
    return message;
  }

  /**
   * Finds Managed User in a given list of Chat Thread receipients
   * @param recipients - List of participants
   * @returns Managed User or undefined
   */
  public findManagedUser(recipients: UserMetaData[]): UserMetaData | undefined {
    return recipients.find((recipient: UserMetaData): boolean => {
      if (recipient.userType === 'Managed') {
        return true;
      }
      return false;
    });
  }

  /**
   * Checks if a child node contains a URL and modifies the message accordingly.
   * @param child - The child node to check.
   * @returns Modified message with URL appended if found.
   */
  private checkForUrlAndModifyMessage(child: ChildNode): string {
    const urlRegex = /(https?:\/\/[^\s]+)/;
    const hasUrl = child.textContent?.match(urlRegex);

    if (hasUrl && hasUrl.length && child.textContent === (child as HTMLAnchorElement).href)
      return `${child.textContent}`;
    return `${child.textContent} (${(child as HTMLAnchorElement).href})`;
  }
  /**
   * Checks the subtype of an application file type and returns the corresponding icon URL.
   * @param subtype - The subtype of the application file.
   * @returns Icon URL corresponding to the subtype, or null if no matching icon found.
   */
  private checkFileSubTypeForApplicationType(subtype: string): string | null {
    switch (subtype) {
      case 'pdf':
        return '/assets/images/icons/pdf.png';
      case 'msword':
      case 'vnd.openxmlformats-officedocument.wordprocessingml.document':
      case 'vnd.oasis.opendocument.text':
      case 'vnd.ms-excel':
      case 'vnd.oasis.opendocument.spreadsheet':
      case 'vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      case 'vnd.oasis.opendocument.presentation':
      case 'vnd.ms-powerpoint':
      case 'vnd.openxmlformats-officedocument.presentationml.presentation':
        return 'assets/images/icons/doc.png';
      case 'zip':
      case 'x-7z-compressed':
      case 'vnd.rar':
        return 'assets/images/icons/compressed-folder.svg';
      default:
        return null;
    }
  }
  /**
   * Constructs a filter string based on provided chat filters.
   * @param filters - The chat filters to apply.
   * @returns Constructed filter string for API query.
   */
  private getFilterString(filters: ChatFilter): string {
    let mainFilterString = filters.subject ? `?search=subject|${filters.subject}` : '';
    mainFilterString += filters.message
      ? mainFilterString
        ? `,message|${filters.message}`
        : `?search=message|${filters.message}`
      : '';

    mainFilterString += filters.startDate
      ? mainFilterString
        ? `&startDate=${filters.startDate.toISOString()}`
        : `?startDate=${filters.startDate.toISOString()}`
      : '';

    mainFilterString += filters.endDate
      ? mainFilterString
        ? `&endDate=${filters.endDate.toISOString()}`
        : `?endDate=${filters.endDate.toISOString()}`
      : '';

    let filterString = filters.user ? `filter=user|${filters.user}` : '';
    filterString += filters.unhandled
      ? filterString
        ? `,requiresAttention|true`
        : `filter=requiresAttention|true`
      : '';
    filterString += filters.to ? (filterString ? `,to|${filters.to.user}` : `filter=to|${filters.to.user}`) : '';
    filterString += filters.from
      ? filterString
        ? `,from|${filters.from.user}`
        : `filter=from|${filters.from.user}`
      : '';

    mainFilterString += filterString ? (mainFilterString ? `&${filterString}` : `?${filterString}`) : '';
    mainFilterString += mainFilterString ? `&page=${filters.page}` : `?page=${filters.page}`;

    return mainFilterString;
  }
}
