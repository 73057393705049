export enum ChartTypes {
  PIE = 'pie',
  LINE = 'line',
  SPLINE = 'spline',
  COLUMN = 'column',
  COLUMN_RANGE = 'columnrange',
  AREA_SPLINE_RANGE = 'areasplinerange',
  BAR = 'bar',
  BUBBLE = 'bubble',
  CUSTOM_BUBBLE = 'custom_bubble',
  AVERAGE = 'average',
  RANGE = 'range',
}
export enum ChartCategory {
  VITALS = 'vitals',
  ASSESSMENT = 'assessment',
  HEALTH_TRACKER = 'healthtracker',
  LABS = 'labs',
  ALERT_SEVERITY = 'alert-severity',
  ALERT_MOST_REPORTED = 'alert-most-reported',
}
