import { ChartGroupByOptions, RangeButtonOption } from '../entities/charts';

/**
 * Display date format based on language settings.
 */
export const CHART_DATE_FORMAT =
  localStorage.getItem('NG_TRANSLATE_LANG_KEY') === 'fr' || localStorage.getItem('NG_TRANSLATE_LANG_KEY') === 'es'
    ? '%d/%m/%y'
    : localStorage.getItem('NG_TRANSLATE_LANG_KEY') === 'zh'
      ? '%y/%m/%d'
      : '%m/%d/%y';
/**
 * Display time format based on language settings.
 */
export const CHART_TIME_FORMAT =
  localStorage.getItem('NG_TRANSLATE_LANG_KEY') === 'zh'
    ? '%I:%M %p'
    : localStorage.getItem('NG_TRANSLATE_LANG_KEY') === 'es' || localStorage.getItem('NG_TRANSLATE_LANG_KEY') === 'fr'
      ? '%H:%M'
      : '%I:%M %p';
/**
 * Display time format with seconds based on language settings.
 */
export const CHART_TIME_SECOND_FORMAT =
  localStorage.getItem('NG_TRANSLATE_LANG_KEY') === 'zh'
    ? '%I:%M:%S %p'
    : localStorage.getItem('NG_TRANSLATE_LANG_KEY') === 'es' || localStorage.getItem('NG_TRANSLATE_LANG_KEY') === 'fr'
      ? '%H:%M:%S'
      : '%I:%M:%S %p';
/**
 * Display tooltip date format
 */
export const CHART_TOOLTIP_DATE_TIME_FORMAT = {
  day: '%b %d, %Y',
  hour: '%b %d, %Y %I:%M %p',
  millisecond: '%b %d, %Y %I:%M %p',
  minute: '%b %d, %Y %I:%M %p',
  month: '%B %Y',
  second: '%b %d, %Y %I:%M:%S %p',
  week: 'Week from %A, %e %b %Y',
  year: '%Y',
};
/**
 * Array of colors used for something.
 */
export const COLORS = ['#5166B1', '#4ABBFE', '#ff6e6e'];
/**
 * Array of severity colors used for something else.
 */
export const SEVERITY_COLORS = ['#ddd', '#34bb6f', '#f3c300', '#ff7b2e', '#e71d36'];
/**
 * Default color used when no specific color is defined.
 */
export const DEFAULT_COLOR = '#5166B1';
/**
 * Default color used when no specific color is defined.
 */
export const DEFAULT_TREND_COLOR = '#51B189';
/**
 * Default color used when no specific color is defined.
 */
export const DEFAULT_AREA_RANGE_COLOR = '#b7d2ed';
/**
 * Default height of the chart.
 */
export const DEFAULT_CHART_HEIGHT = '200';
/**
 * Height of the chart when expanded.
 */
export const EXPANDED_CHART_HEIGHT = '400';
/**
 * Default number of days used in some context.
 */
export const DEFAULT_NUMBER_OF_DAYS = 30;
/**
 * Array of button options for chart selection.
 */
export const RANGE_BUTTONS: RangeButtonOption[] = [
  {
    label: '1W',
    value: 7,
    active: false,
  },
  {
    label: '1M',
    value: 30,
    active: true,
  },
  {
    label: '3M',
    value: 90,
    active: false,
  },
  {
    label: '1Y',
    value: 365,
    active: false,
  },
];
/**
 * Array of button options for chart selection.
 */
export const CHART_GROUP_BY_OPTIONS: ChartGroupByOptions[] = [
  { label: 'Automatic', key: 'automatic', value: 'automatic' },
  { label: 'Minutes', key: 'minute', value: '1MI' },
  { label: 'Hours', key: 'hours', value: '1H' },
  { label: 'Day', key: 'day', value: '1D' },
  { label: 'Month', key: 'month', value: '1MO' },
];
