import { SafeUrl } from '@angular/platform-browser';
import { GenericTable } from 'src/app/modules/shared/interfaces/common.entities';
/**
 * Enum defining the tag for a new comment made by an agent.
 */
export enum NewCommentByAgent {
  /**
   * New comment by agent event.
   */
  new_comment_by_agent = 'ticket-status-unread',
}
/**
 * Interface for mapping user information to Zendesk format.
 */
export interface MapUserZendeskPostO {
  /**
   * Details of the user.
   */
  user: {
    /**
     * The user's name.
     */
    name: string;
    /**
     * The user's email address.
     */
    email: string;
    /**
     * The user's organization details.
     */
    organization: {
      /**
       * The name of the user's organization.
       */
      name: string;
    };
  };
}
/**
 * Interface for Zendesk ticket form data.
 */
export interface zendeskTicketFormData {
  /**
   * The subject of the Zendesk ticket.
   */
  subject: string;
  /**
   * The body or description of the Zendesk ticket.
   */
  body: string;
  /**
   * The title key for the Zendesk ticket.
   */
  titleKey: string;
  /**
   * Optional array of file tokens associated with the ticket.
   */
  fileTokens?: string[];
}
/**
 * Interface for Zendesk ticket post data.
 */
export interface zendeskTicketPostData {
  /**
   * The ticket data to be posted to Zendesk.
   */
  ticket: Ticket;
}
/**
 * Interface representing a Zendesk ticket.
 */

export interface Ticket {
  /**
   * The status of the ticket.
   */
  status: string;
  /**
   * The subject of the ticket.
   */
  subject: string;
  /**
   * The comment associated with the ticket.
   */
  comment: {
    /**
     * The body of the comment.
     */
    body: string;
    /**
     * Optional array of upload strings.
     */
    uploads?: string[];
  };
  /**
   * The ID of the requester (if available).
   */
  requester_id: string | null;
  /**
   * The ID of the submitter (if available).
   */
  submitter_id: string | null;
  /**
   * The type of the ticket.
   */
  type: string;
  /**
   * The channel via which the ticket was submitted.
   */
  via: {
    /**
     * The channel type.
     */
    channel: string;
  };
  /**
   * Custom fields associated with the ticket.
   */
  custom_fields: Array<{
    /**
     * The ID of the custom field.
     */
    id: string /**
     * The value of the custom field.
     */;
    value: string;
  }>;
}
/**
 * Interface representing details of a ticket.
 */
export interface TicketDetails {
  /**
   * The ID of the ticket.
   */
  id: GenericTable;
  /**
   * The status of the ticket.
   */
  status: GenericTable;
  /**
   * The subject of the ticket.
   */
  subject: GenericTable;
  /**
   * The date and time when the ticket was last updated.
   */
  updated_at: GenericTable;
}
/**
 * Interface representing a ticket associated with the current user.
 */
export interface MyTicket {
  /** URL of the ticket. */
  url: string;
  /** Unique identifier of the ticket. */
  id: number;
  /** External ID of the ticket, if available. */
  external_id: string | null;
  /** Information about how the ticket was created. */
  via: {
    /** Channel through which the ticket was created. */
    channel: string;
    /** Source details of the ticket creation. */
    source: {
      from: object;
      to: object;
      rel: string | null;
    };
  };
  /** Date and time when the ticket was created. */
  created_at: string;
  /** Date and time when the ticket was last updated. */
  updated_at: string;
  /** Type of the ticket. */
  type: string;
  /** Subject of the ticket. */
  subject: string;
  /** Raw subject of the ticket. */
  raw_subject: string;
  /** Description of the ticket. */
  description: string;
  /** Priority of the ticket, if assigned. */
  priority: string | null;
  /** Current status of the ticket. */
  status: string;
  /** Recipient of the ticket, if applicable. */
  recipient: string | null;
  /** ID of the user who requested the ticket. */
  requester_id: number;
  /** ID of the user who submitted the ticket. */
  submitter_id: number;
  /** ID of the user who is assigned to the ticket. */
  assignee_id: number;
  /** ID of the organization associated with the ticket. */
  organization_id: string | number | null;
  /** ID of the group associated with the ticket. */
  group_id: string | number | null;
  /** IDs of collaborators associated with the ticket. */
  collaborator_ids: Array<string | object>;
  /** IDs of followers of the ticket. */
  follower_ids: Array<string | object>;
  /** IDs of email CCs associated with the ticket. */
  email_cc_ids: Array<string | object>;
  /** ID of the forum topic related to the ticket. */
  forum_topic_id: string | number | null;
  /** ID of the problem related to the ticket. */
  problem_id: string | number | null;
  /** Indicates if the ticket has incidents. */
  has_incidents: boolean;
  /** Indicates if the ticket is public. */
  is_public: boolean;
  /** Due date and time of the ticket, if set. */
  due_at: string | number | null;
  /** Tags associated with the ticket. */
  tags: Array<string | object>;
  /** Custom fields associated with the ticket. */
  custom_fields: Array<{ id: string; value: string }>;
  /** Satisfaction rating given for the ticket, if provided. */
  satisfaction_rating: string | number | null;
  /** IDs of sharing agreements associated with the ticket. */
  sharing_agreement_ids: Array<string | object>;
  /** Fields associated with the ticket. */
  fields: Array<{ id: string; value: string }>;
  /** IDs of follow-up actions associated with the ticket. */
  followup_ids: Array<string | object>;
  /** ID of the ticket form used for the ticket. */
  ticket_form_id: number;
  /** ID of the brand associated with the ticket. */
  brand_id: number;
  /** Indicates if channelback is allowed for the ticket. */
  allow_channelback: boolean;
  /** Indicates if attachments are allowed for the ticket. */
  allow_attachments: boolean;
  /** Indicates if the ticket originated from a messaging channel. */
  from_messaging_channel: boolean;
}
/**
 * Interface representing an uploaded attachment.
 */
export interface UploadAttachment {
  /**
   * Optional icon associated with the attachment.
   * It can be a URL string or a SafeUrl object.
   */
  icon?: string | SafeUrl;

  /**
   * Optional file icon associated with the attachment.
   * It can be a URL string or a SafeUrl object.
   */
  fileIcon?: string | SafeUrl;

  /**
   * The file object representing the attachment.
   */
  file: File;

  /**
   * Optional upload progress percentage (0-100).
   * Set to null if upload progress is not available.
   */
  uploadProgress?: number | null;
}
/**
 * Interface representing an attachment associated with a comment.
 */
export interface Attachment {
  /**
   * The URL where the attachment can be accessed.
   */
  url: string;
  /**
   * The ID of the attachment.
   */
  id: number;
  /**
   * The file name of the attachment, can be null or undefined.
   */
  file_name: string | null | undefined;
  /**
   * The content URL of the attachment.
   */
  content_url: string;
  /**
   * The mapped content URL of the attachment.
   */
  mapped_content_url: string;
  /**
   * The content type of the attachment.
   */
  content_type: string;
  /**
   * The size of the attachment in bytes.
   */
  size: number;
  /**
   * The width of the attachment, can be a number, string, or null.
   */
  width: number | string | null;
  /**
   * The height of the attachment, can be a number, string, or null.
   */
  height: number | string | null;
  /**
   * Indicates whether the attachment should be displayed inline.
   */
  inline: boolean;
  /**
   * Indicates whether the attachment has been deleted.
   */

  deleted: boolean;
  /**
   * Indicates whether there is an override for malware access.
   */
  malware_access_override: boolean;
  /**
   * The result of the malware scan for the attachment.
   */
  malware_scan_result: string;
  /**
   * Array of URLs for thumbnails of the attachment.
   */
  thumbnails: string[];
}
/**
 * Interface representing a comment associated with a ticket.
 */
export interface Comment {
  /**
   * The ID of the comment.
   */
  id: string;
  /**
   * The type of the comment.
   */
  type: string;
  /**
   * The ID of the author of the comment.
   */
  author_id: number;
  /**
   * The body of the comment in plain text.
   */
  body: string;
  /**
   * The body of the comment in HTML format.
   */
  html_body: string;
  /**
   * The plain body of the comment (may be same as `body`).
   */
  plain_body: string;
  /**
   * Indicates whether the comment is public.
   */
  public: boolean;
  /**
   * Array of attachments associated with the comment.
   */

  attachments: Attachment[];
  /**
   * The audit ID associated with the comment.
   */
  audit_id: number;
  /**
   * Details about how the comment was submitted.
   */
  via: {
    /**
     * The channel through which the comment was submitted.
     */
    channel: string;
    /**
     * Details about the source of the comment.
     */
    source: {
      /**
       * Object representing the sender of the comment.
       */
      from: object;
      /**
       * Object representing the recipient of the comment.
       */
      to: object;
      /**
       * Relationship identifier, can be a number, string, or null.
       */
      rel: number | string | null;
    };
  };
  /**
   * The date and time when the comment was created.
   */
  created_at: string;
  /**
   * Metadata associated with the comment.
   */
  metadata: {
    /**
     * System-related metadata.
     */
    system: {
      /**
       * Client information associated with the comment.
       */
      client: string;
      /**
       * IP address from which the comment was submitted.
       */
      ip_address: string;
      /**
       * Location associated with the comment.
       */
      location: string;
      /**
       * Latitude coordinate associated with the comment.
       */
      latitude: number;
      /**
       * Longitude coordinate associated with the comment.
       */
      longitude: number;
    };
    /**
     * Custom metadata object associated with the comment.
     */
    custom: object;
  };
}
/**
 * Interface representing a user who has commented on a ticket.
 */
export interface CommentedUser {
  /**
   * The user's username.
   */
  userName: string;

  /**
   * The user's email address.
   */
  email: string;

  /**
   * The role of the user.
   */
  role: string;

  /**
   * The ID of the user.
   */
  id: number;

  /**
   * The date and time when the user was created.
   */
  created_at: string;
}
/**
 * Interface representing data to add a comment to a ticket.
 */
export interface AddComment {
  /**
   * The ticket to which the comment will be added.
   */
  ticket: AddCommentPostO;
}
/**
 * Interface representing post data to add a comment to a ticket.
 */
export interface AddCommentPostO {
  /**
   * The comment to be added.
   */
  comment: {
    /**
     * The body or content of the comment.
     */
    body: string;

    /**
     * Array of upload tokens associated with the comment.
     */
    uploads: string[];

    /**
     * The ID of the author of the comment.
     */
    author_id: number;
  };
}
