import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { AddEditUserGroupComponent } from 'src/app/modules/shared/components/add-edit-user-group/add-edit-user-group.component';
import { DirectivesModule } from 'src/app/modules/shared/directives/directive.module';
import { ClientLabelPipe } from 'src/app/modules/shared/pipes/client-label.pipe';
import { FilterPipe } from 'src/app/modules/shared/pipes/filter.pipe';
import { TranslatePipe } from 'src/app/modules/shared/pipes/translate.pipe';
import { SharedModule } from 'src/app/modules/shared/shared.module';
import { AccessComponent } from './components/access/access.component';
import { AddUserComponent } from './components/add-user/add-user.component';
// import { ManagedAddComponent } from './components/managed-add-dialog/managed-add.component';
// import { ManagedDialogComponent } from './components/managed-group-dialog/managed-group.component';
// import { ManagedUserComponent } from './components/managed-user-dialog/managed-user.component';
import { ProfileEditComponent } from './components/profile-edit/profile-edit.component';
import { UsersListingComponent } from './components/users-listing/users-listing.component';
import { UsersRoutingModule } from './users-routing.module';

@NgModule({
  declarations: [
    AddUserComponent,
    UsersListingComponent,
    ProfileEditComponent,
    AccessComponent,
    // ManagedDialogComponent,
    // ManagedUserComponent,
    // ManagedAddComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    DirectivesModule,
    UsersRoutingModule,
    AddEditUserGroupComponent,
    MatMenuModule,
    TranslatePipe,
    FilterPipe,
    ClientLabelPipe,
  ],
})
export class UsersModule {}
