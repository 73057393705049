import { Directive, Input, Renderer2, TemplateRef, ViewContainerRef } from '@angular/core';
import { PermissionsService } from 'src/app/modules/permissions/services/permissions.service';
/**
 * Directive to conditionally render elements based on permissions and disable elements if permissions are not met.
 * This directive interacts with the PermissionsService to check user permissions.
 */
@Directive({
  selector: '[appPermissions]',
})
export class PermissionsDirective {
  /** Flag to track whether the view is currently rendered or not */
  private hasView = false;

  /**
   * Constructs the PermissionsDirective.
   *
   * @param templateRef Reference to the embedded template that the directive is applied to.
   * @param viewContainer Reference to the container where the embedded view is created.
   * @param permissionsService Service for checking user permissions.
   * @param renderer Renderer for modifying DOM elements.
   */
  constructor(
    private templateRef: TemplateRef<unknown>,
    private viewContainer: ViewContainerRef,
    private permissionsService: PermissionsService,
    private renderer: Renderer2,
  ) {}

  /**
   * Input property that applies conditional rendering based on the specified permission condition.
   *
   * @param condition The permission condition to check against.
   */
  @Input() set appPermissions(condition: string) {
    if (this.permissionsService.hasPermission(condition) && !this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (!this.permissionsService.hasPermission(condition) && this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }

  /**
   * Input property that disables the element if the specified permission condition is not met.
   *
   * @param condition The permission condition to check against.
   */
  @Input() set appPermissionsDisabled(condition: string) {
    if (!this.permissionsService.hasPermission(condition)) {
      this.renderer.setAttribute(this.templateRef.elementRef.nativeElement.previousSibling, 'disabled', 'true');
      this.renderer.addClass(this.templateRef.elementRef.nativeElement.previousSibling, 'disabled');
    }
  }
}
