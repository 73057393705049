import { Point } from '@angular/cdk/drag-drop';
import { Injectable, Signal, WritableSignal, computed, inject, signal } from '@angular/core';
import {
  AudioDeviceInfo,
  Call,
  CallAgent,
  DeviceManager,
  JoinCallOptions,
  LocalVideoStream,
  RemoteParticipant,
  RoomCallLocator,
  VideoDeviceInfo,
} from '@azure/communication-calling';
import { CommunicationUserIdentifier } from '@azure/communication-common';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Subject, firstValueFrom } from 'rxjs';
import { ACSService } from 'src/app/modules/shared/services/acs.service';
import { BackdropAndLoaderService } from 'src/app/modules/shared/services/backdrop-and-loader.service';
import { HttpService } from 'src/app/modules/shared/services/http.service';
import { environment } from 'src/environments/environment';
import { DemographicData, TableSort } from '../../shared/interfaces/common.entities';
import {
  Attendee,
  CallParticipant,
  CallRecordingResponse,
  VideoLobby,
  VideoLobbyDeviceSettings,
} from '../entities/video-lobby.entities';
/**
 * Service handling various operations related to the Video Lobby feature,
 * including managing calls, participants, permissions, and settings.
 */
@Injectable({
  providedIn: 'root',
})
export class VideoLobbyService {
  /**
   * Signal indicating microphone status: 'Off', 'On', or 'PermissionDenied'.
   */
  public microphoneStatus: WritableSignal<'Off' | 'On' | 'PermissionDenied'> = signal('Off');
  /**
   * Signal indicating camera status: 'Off', 'On', or 'PermissionDenied'.
   */
  public cameraStatus: WritableSignal<'Off' | 'On' | 'PermissionDenied'> = signal('Off');
  /**
   * Signal representing the local video stream.
   */
  public localVideoStream: WritableSignal<LocalVideoStream | null> = signal(null);
  /**
   * Signal representing the list of call participants.
   */
  public callParticipants: WritableSignal<CallParticipant[]> = signal([]);
  /**
   * Signal representing the joined patient participant.
   */
  public joinedPatient: WritableSignal<RemoteParticipant | null> = signal(null);
  /**
   * Signal representing the active call instance.
   */
  public activeCall: WritableSignal<VideoLobby | null> = signal(null);
  /**
   * Signal representing the recording ID.
   */
  public recordingId: WritableSignal<string> = signal('');
  /**
   * Signal representing the call duration in 'HH:MM:SS' format.
   */
  public callDuration: WritableSignal<string> = signal('00:00:00');
  /**
   * Signal indicating Picture-in-Picture (PiP) mode.
   */
  public pipMode: WritableSignal<boolean> = signal(false);
  /**
   * Signal indicating the active side menu state: 'participants', 'deviceSettings', or null.
   */
  public sideMenu: WritableSignal<'participants' | 'deviceSettings' | null> = signal(null);
  /**
   * Signal indicating screen sharing status: 'None', 'Self', or 'Others'.
   */
  public isScreenSharingInProgress: WritableSignal<'None' | 'Self' | 'Others'> = signal('None');
  /**
   * Signal indicating whether video inversion is enabled.
   */
  public invertVideo!: WritableSignal<boolean>;
  /**
   * Signal indicating whether the recording button should be disabled.
   */
  public disableRecordingButton: WritableSignal<boolean> = signal(false);
  /**
   * Signal representing the position of a dialog on the screen.
   */
  public dialogPosition: WritableSignal<Point> = signal({
    x: 0,
    y: 0,
  });

  /**
   * Computed signal representing the number of joined participants in the call.
   */
  public numberOfJoinedParticipants: Signal<number> = computed(() => {
    let total = 0;
    this.callParticipants().forEach((participant) => {
      if (participant.remoteData()) total++;
    });
    return total;
  });
  /**
   * BehaviorSubject indicating whether a call is in progress.
   */
  public callInProgress$ = new BehaviorSubject<boolean>(false);
  /**
   * Subject that signals when dialog loading is complete.
   */
  public dialogLoadingComplete = new Subject<boolean>();
  /**
   * Title displayed when no video permission is granted.
   */
  public noVideoPermissionTitle = 'Enable Camera and Microphone';
  /**
   * Message displayed when no video permission is granted.
   */
  public noVideoPermissionMessage = '';
  /**
   * Instance of the device manager used for managing audio and video devices.
   */
  public deviceManager!: DeviceManager;
  /**
   * Demographic data of the user obtained from local storage.
   */
  public userDemographicData: DemographicData = JSON.parse(localStorage.getItem('demographicData') || '{}');
  /**
   * ACS (Azure Communication Services) ID associated with the call.
   */
  public acsId = '';
  /**
   * Instance of the active call object.
   */
  public call: Call | null = null;
  /**
   * Settings for video lobby devices such as microphone, camera, and speakers.
   */
  public videoDeviceSettings!: VideoLobbyDeviceSettings;
  /**
   * Base URL for API requests.
   */
  #baseUrl: string = environment.url.apiHost;
  /**
   * API version used for API requests.
   */
  #apiVersion: string = environment.url.version;
  /**
   * Injected instance of the ACS (Azure Communication Services) service.
   */
  #acsService = inject(ACSService);
  /**
   * Instance of the call agent used for managing calls.
   */
  #callAgent!: CallAgent;
  /**
   * Timestamp indicating the start time of the current call.
   */
  #callStartTime!: Date;
  /**
   * Interval ID used for updating call duration.
   */
  #callInterval!: ReturnType<typeof setInterval>;
  /**
   * Injected instance of the backdrop and loader service.
   */
  #backdropAndLoaderService = inject(BackdropAndLoaderService);
  /**
   * Injected instance of the HTTP service.
   */
  #http = inject(HttpService);
  /**
   * Injected instance of the Toastr service for displaying notifications.
   */
  #toast = inject(ToastrService);
  /**
   * Initializes the Video Lobby service by setting up necessary dependencies
   * and retrieving initial settings.
   */
  public async initVideoLobby(): Promise<void> {
    await this.#acsService.initVideoLobby();
    if (this.#acsService.callClient && this.#acsService.callAgent) {
      this.acsId = await this.#acsService.getACSToken();
      this.#callAgent = this.#acsService.callAgent;
      this.deviceManager = await this.#acsService.callClient.getDeviceManager();
      this.videoDeviceSettings = JSON.parse(
        localStorage.getItem('videoLobbyDeviceSettings') ||
          JSON.stringify({
            selectedMic: this.deviceManager.selectedMicrophone?.id || '',
            selectedSpeaker: this.deviceManager.selectedSpeaker?.id || '',
            selectedCamera: '',
            selfVideoInverted: false,
          }),
      );
      this.invertVideo = signal(this.videoDeviceSettings.selfVideoInverted);
      this.#setVideoLobbyDeviceSettings();
    }
  }
  /**
   * Retrieves a list of video lobby calls based on specified date range.
   * @param dateO Object containing start and end time for filtering calls.
   * @returns Observable for fetching lobby list.
   */
  public getLobbyList(dateO: { startTime: string; endTime: string }) {
    const url = `${this.#baseUrl}${this.#apiVersion}/videolobby?startTime=${dateO.startTime}&endTime=${dateO.endTime}`;
    return this.#http.get(url, {}, true);
  }
  /**
   * Retrieves details of a specific video lobby call by its ID.
   * @param id ID of the call to retrieve details for.
   * @returns Observable for fetching call details by ID.
   */
  public getCallDetailsListId(id: string) {
    const url = `${this.#baseUrl}${this.#apiVersion}${'/videoLobby/recording/'}${id}`;
    return this.#http.get(url);
  }
  /**
   * Retrieves a paginated list of video lobby call details with optional sorting.
   * @param limit Maximum number of records per page.
   * @param offset Offset for pagination.
   * @param sort Sorting criteria for the list.
   * @returns Observable for fetching paginated call details.
   */
  public getCallDetailsList(limit: number, offset: number, sort: TableSort | null) {
    const sorting = sort ? sort?.column + '|' + sort?.order : 'patientName|DESC';
    const url = `${this.#baseUrl}${this.#apiVersion}${'/videoLobby/recordings'}?limit=${limit}&page=${offset}&sort=${sorting}`;
    return this.#http.get(url);
  }
  /**
   * Retrieves a paginated and filtered list of video lobby call details.
   * @param limit Maximum number of records per page.
   * @param offset Offset for pagination.
   * @param sort Sorting criteria for the list.
   * @param filter Filter criteria for the list.
   * @returns Observable for fetching filtered call details.
   */
  public getCallDetailsListFilter(limit: number, offset: number, sort: TableSort | null, searchKey: string) {
    const sorting = sort ? sort?.column + '|' + sort?.order : 'patientName|DESC';
    const filterApply = `id${'|'}${searchKey}`;
    const url = `${this.#baseUrl}${this.#apiVersion}${'/videoLobby/recordings'}?limit=${limit}&page=${offset}&filter=${filterApply}&sort=${sorting}`;
    return this.#http.get(url);
  }
  /**
   * Checks camera and microphone permission for the current user.
   * @returns Promise resolving to true if permissions are granted, false otherwise.
   */
  public async checkCameraMicrophonePermission(): Promise<boolean> {
    try {
      await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });
      return true;
    } catch (error) {
      this.noVideoPermissionMessage = `Access to your webcam and microphone is currently unavailable. Please navigate to your media settings by clicking on the camera icon located on the browser address bar to enable access. For further information <a href="${this.#getSupportLink()}" target="_blank">click here</a>.`;
      this.microphoneStatus.set('PermissionDenied');
      this.cameraStatus.set('PermissionDenied');
      return false;
    }
  }
  /**
   * Creates a local video stream for the current user based on selected camera.
   */
  public async createLocalVideoStream(): Promise<void> {
    if (this.videoDeviceSettings.selectedCamera) {
      const camera: VideoDeviceInfo | undefined = (await this.deviceManager.getCameras()).find(
        (camera) => camera.id === this.videoDeviceSettings.selectedCamera,
      );
      if (camera) {
        this.localVideoStream.set(new LocalVideoStream(camera));
        return;
      }
    }
    const camera: VideoDeviceInfo = (await this.deviceManager.getCameras())[0];
    this.localVideoStream.set(new LocalVideoStream(camera));
    this.videoDeviceSettings.selectedCamera = camera.id;
  }
  /**
   * Starts a call in the video lobby with the specified room ID.
   * @param roomId ID of the room to join the call.
   */
  public async startCall(roomId: string): Promise<void> {
    this.#http.loader.next(true);
    const roomCallLocator: RoomCallLocator = {
      roomId,
    };
    if (!this.localVideoStream()) {
      this.createLocalVideoStream();
    }
    const joinCallOptions: JoinCallOptions = {
      videoOptions: {
        localVideoStreams: [this.localVideoStream()!],
      },
      audioOptions: {
        muted: this.microphoneStatus() !== 'On',
      },
    };

    this.call = this.#callAgent.join(roomCallLocator, joinCallOptions);
    this.#startCallSubscriptions();
  }
  /**
   * Ends an ongoing call and resets call-related states.
   *
   * @param forEveryone - Flag indicating if the call should end for everyone.
   * @returns A promise that resolves when the call is successfully ended.
   */
  public async endCall(forEveryone: boolean): Promise<void> {
    this.stopScreenShare();
    if (this.call) {
      await this.call.hangUp({
        forEveryone: forEveryone,
      });
    }
    this.activeCall.set(null);
    this.callParticipants.set([]);
    this.joinedPatient.set(null);
    this.call = null;
    this.sideMenu.set(null);
  }
  /**
   * Mutes the audio in the current call.
   *
   * @returns A promise that resolves when audio is successfully muted.
   */
  public async muteAudio(): Promise<void> {
    if (this.call) {
      await this.call.mute();
    }
    this.microphoneStatus.set('Off');
  }
  /**
   * Unmutes the audio in the current call.
   *
   * @returns A promise that resolves when audio is successfully unmuted.
   */
  public async unmuteAudio(): Promise<void> {
    if (this.call) {
      await this.call.unmute();
    }
    this.microphoneStatus.set('On');
  }
  /**
   * Mutes the video in the current call.
   *
   * @returns A promise that resolves when video is successfully muted.
   */
  public async muteVideo(): Promise<void> {
    if (this.call && this.localVideoStream()) {
      await this.call.stopVideo(this.localVideoStream()!);
    }
  }
  /**
   * Unmutes the video in the current call.
   *
   * @returns A promise that resolves when video is successfully unmuted.
   */
  public async unmuteVideo(): Promise<void> {
    if (!this.call) return;
    await this.call.startVideo(this.localVideoStream()!);
  }
  /**
   * Starts recording the current call.
   *
   * @returns A promise that resolves when call recording is successfully started.
   */
  public async startCallRecording(): Promise<void> {
    if (this.disableRecordingButton()) return;
    this.disableRecordingButton.set(true);
    const url = `${this.#baseUrl}${this.#apiVersion}/videolobby/record/start/${this.activeCall()!.roomId}`;
    const serverCallId: string = await this.call!.info.getServerCallId();
    const res: CallRecordingResponse = await firstValueFrom(
      this.#http.post(
        url,
        {
          serverCallId,
        },
        {},
        true,
      ),
    );
    this.recordingId.set(res.recordingId || '');
    this.disableRecordingButton.set(false);
  }
  /**
   * Stops recording the current call.
   *
   * @returns A promise that resolves when call recording is successfully stopped.
   */
  public async stopCallRecording(): Promise<void> {
    if (this.disableRecordingButton()) return;
    if (!this.recordingId() || this.recordingId() === 'No ID') return;

    this.disableRecordingButton.set(true);
    const url = `${this.#baseUrl}${this.#apiVersion}/videolobby/record/stop/${this.activeCall()!.roomId}`;
    const serverCallId: string = await this.call!.info.getServerCallId();
    await firstValueFrom(
      this.#http.post(
        url,
        {
          serverCallId,
          recordingId: this.recordingId(),
        },
        {},
        true,
      ),
    );
    this.recordingId.set('');
    this.disableRecordingButton.set(false);
  }
  /**
   * Checks if a patient has joined the current call.
   *
   * @param patient - The attendee to check.
   * @returns The remote participant corresponding to the patient, or null if not found.
   */
  public checkWhetherPatientHasJoined(patient: Attendee | undefined): RemoteParticipant | null {
    if (this.call!.remoteParticipants.length && patient) {
      return (
        this.call!.remoteParticipants.find(
          (participant: RemoteParticipant): boolean =>
            (participant.identifier as CommunicationUserIdentifier).communicationUserId === patient.acsId,
        ) || null
      );
    }
    return null;
  }
  /**
   * Exits Picture-in-Picture (PiP) mode.
   * Resets dialog position, shows backdrop, and sets PiP mode to false.
   */
  public exitPip(): void {
    this.dialogPosition.set({
      x: 0,
      y: 0,
    });
    this.#backdropAndLoaderService.showBackdrop.set(true);
    this.pipMode.set(false);
  }
  /**
   * Enters Picture-in-Picture (PiP) mode.
   * Hides backdrop, sets PiP mode to true, and adjusts dialog position.
   */
  public enterPip(): void {
    this.#backdropAndLoaderService.showBackdrop.set(false);
    this.pipMode.set(true);
    this.dialogPosition.set({
      x: window.innerWidth / 2 - 200,
      y: window.innerHeight / 2 - 200,
    });
  }
  /**
   * Starts screen sharing in the current call.
   * Displays an error toast if another participant is already screen sharing.
   * Sets screen sharing mode and subscribes to screen sharing change events.
   */
  public async startScreenShare(): Promise<void> {
    if (this.isScreenSharingInProgress() === 'Others') {
      this.#toast.error('Another Screen Sharing is in progress. Only one participant can share screen at a time.');
      return;
    }
    await this.call!.startScreenSharing();
    this.isScreenSharingInProgress.set('Self');
    this.call!.on('isScreenSharingOnChanged', this.#isScreenSharingOnChanged);
  }
  /**
   * Stops screen sharing in the current call.
   * Unsubscribes from screen sharing change events and resets screen sharing mode.
   */
  public async stopScreenShare(): Promise<void> {
    if (this.call?.isScreenSharingOn) {
      try {
        await this.call!.stopScreenSharing();
      } catch {}
    }
    if (this.call) this.call!.off('isScreenSharingOnChanged', this.#isScreenSharingOnChanged);
    this.isScreenSharingInProgress.set('None');
  }
  /**
   * Requests check-in to a video lobby room.
   *
   * @param id - The ID of the room to check in.
   * @returns A promise that resolves when the check-in request is successful.
   */
  public async requestCheckIn(id: string): Promise<void> {
    const url = `${this.#baseUrl}${this.#apiVersion}/videolobby/room/${id}/checkIn`;
    await firstValueFrom(this.#http.post(url, {}, {}, false));
  }
  /**
   * Saves device settings for microphone, camera, speaker, or video inversion to local storage.
   *
   * @param device - The type of device setting to save.
   */
  public saveVideoLobbyDeviceSettings(device: 'mic' | 'camera' | 'speaker' | 'invertVideo'): void {
    const settingsString = localStorage.getItem('videoLobbyDeviceSettings');
    if (settingsString) {
      const settings: VideoLobbyDeviceSettings = JSON.parse(settingsString);
      if (device === 'mic') settings.selectedMic = this.videoDeviceSettings.selectedMic;
      if (device === 'camera') settings.selectedCamera = this.videoDeviceSettings.selectedCamera;
      if (device === 'speaker') settings.selectedSpeaker = this.videoDeviceSettings.selectedSpeaker;
      if (device === 'invertVideo') settings.selfVideoInverted = this.videoDeviceSettings.selfVideoInverted;
      localStorage.setItem('videoLobbyDeviceSettings', JSON.stringify(settings));
      return;
    }
    localStorage.setItem('videoLobbyDeviceSettings', JSON.stringify(this.videoDeviceSettings));
  }
  /**
   * Sets video lobby device settings based on selected microphone and speaker.
   * If selected devices differ from current settings, updates the device manager selections.
   * Updates `videoDeviceSettings` with selected microphone and speaker IDs.
   *
   * @remarks
   * This method interacts with the device manager to select appropriate audio devices.
   *
   * @returns A Promise that resolves when device settings are updated.
   */
  async #setVideoLobbyDeviceSettings(): Promise<void> {
    if (this.deviceManager.selectedSpeaker?.id !== this.videoDeviceSettings.selectedSpeaker) {
      const speaker: AudioDeviceInfo | undefined = (await this.deviceManager.getSpeakers()).find(
        (speaker) => speaker.id === this.videoDeviceSettings.selectedSpeaker,
      );
      if (speaker) {
        this.deviceManager.selectSpeaker(speaker);
        this.videoDeviceSettings.selectedSpeaker = speaker.id;
      }
    }

    if (this.deviceManager.selectedMicrophone?.id !== this.videoDeviceSettings.selectedMic) {
      const mic: AudioDeviceInfo | undefined = (await this.deviceManager.getMicrophones()).find(
        (mic) => mic.id === this.videoDeviceSettings.selectedMic,
      );
      if (mic) {
        this.deviceManager.selectMicrophone(mic);
        this.videoDeviceSettings.selectedMic = mic.id;
      }
    }
  }
  /**
   * Handles changes in screen sharing status.
   * If screen sharing is no longer active, unsubscribes from related events and updates `isScreenSharingInProgress` state.
   *
   * @private
   */
  #isScreenSharingOnChanged = () => {
    if (!this.call?.isScreenSharingOn) {
      this.call!.off('isScreenSharingOnChanged', this.#isScreenSharingOnChanged);
      this.isScreenSharingInProgress.set('None');
    }
  };
  /**
   * Retrieves the support link based on the current browser's user agent.
   * Determines the appropriate support link for browsers such as Chrome, Safari, Edge, Firefox, etc.
   *
   * @returns A string representing the support link for the detected browser.
   * @private
   */
  #getSupportLink(): string {
    const browser: BROWSER_ENUM = this.#detectBrowser();
    if (browser === BROWSER_ENUM.SAFARI) {
      return 'https://support.apple.com/en-in/guide/safari/ibrwe2159f50/mac';
    } else if (browser === BROWSER_ENUM.EDGE) {
      return 'https://support.microsoft.com/en-us/windows/windows-camera-microphone-and-privacy-a83257bc-e990-d54a-d212-b5e41beba857';
    } else {
      return 'https://support.google.com/chrome/answer/2693767?hl=en';
    }
  }
  /**
   * Detects the current browser based on the user agent.
   *
   * @returns The browser type as an enumerated value (BROWSER_ENUM).
   * @private
   */
  #detectBrowser(): BROWSER_ENUM {
    const userAgent: string = navigator.userAgent;
    switch (true) {
      case /edg/i.test(userAgent):
        return BROWSER_ENUM.EDGE;
      case /trident/i.test(userAgent):
        return BROWSER_ENUM.INTERNET_EXPLORER;
      case /firefox|fxios/i.test(userAgent):
        return BROWSER_ENUM.FIRE_FOX;
      case /opr\//i.test(userAgent):
        return BROWSER_ENUM.OPERA;
      case /ucbrowser/i.test(userAgent):
        return BROWSER_ENUM.UC_BROWSER;
      case /samsungbrowser/i.test(userAgent):
        return BROWSER_ENUM.SAMSUNG_BROWSER;
      case /chrome|chromium|crios/i.test(userAgent):
        return BROWSER_ENUM.CHROME;
      case /safari/i.test(userAgent):
        return BROWSER_ENUM.SAFARI;
      default:
        return BROWSER_ENUM.UNKNOWN;
    }
  }
  /**
   * Starts subscriptions for call state changes.
   * Monitors the call state and updates related observable states such as `callInProgress$`, `activeCall`, `callParticipants`, and `callDuration`.
   *
   * @private
   */
  #startCallSubscriptions(): void {
    if (!this.call) return;
    this.call.on('stateChanged', () => {
      if (this.call!.state === 'Connected') {
        this.callInProgress$.next(true);
        this.#callStartTime = new Date();
        this.#callInterval = setInterval(() => {
          const diff = new Date().getTime() - this.#callStartTime.getTime();
          const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((diff % (1000 * 60)) / 1000);
          this.callDuration.set(
            `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`,
          );
        }, 1000);
        this.#http.loader.next(false);
      } else if (this.call!.state === 'Disconnected') {
        // Unsubscribe to all events
        this.callInProgress$.next(false);
        this.activeCall.set(null);
        this.callParticipants.set([]);
        this.callDuration.set('00:00:00');
        clearInterval(this.#callInterval);
        this.stopCallRecording();
        this.dialogPosition.set({
          x: 0,
          y: 0,
        });
        this.#http.loader.next(false);
      }
    });
  }
}
/**
 * Enumeration for browser types.
 */
enum BROWSER_ENUM {
  EDGE,
  INTERNET_EXPLORER,
  FIRE_FOX,
  OPERA,
  UC_BROWSER,
  SAMSUNG_BROWSER,
  CHROME,
  SAFARI,
  UNKNOWN,
}
