import { Injectable } from '@angular/core';
import { Utils } from '../../shared/utils/utils';
/**
 * Service to manage user permissions based on user configurations stored in localStorage.
 * Provides methods to retrieve user permissions and check access rights.
 */
@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  /**
   * Retrieves user configurations stored in localStorage.
   * @returns User configurations if available, otherwise returns null.
   */
  getUserConfigurations() {
    const userConfigurations = localStorage.getItem('userConfigurations');
    return userConfigurations ? JSON.parse(userConfigurations) : null;
  }

  /**
   * Retrieves all user permissions from user configurations.
   * @returns User permissions if available, otherwise returns null.
   */
  getUserPermissions() {
    return this.getUserConfigurations() && this.getUserConfigurations().permissionSettings;
  }

  /**
   * Retrieves self-specific permissions from user configurations.
   * @returns Array of self-specific permissions, or an empty array if not available.
   */
  getSelfPermissions() {
    return (this.getUserConfigurations() && this.getUserConfigurations().permissionSettings?.self) || [];
  }

  /**
   * Retrieves permissions for others (non-self) from user configurations.
   * @returns Array of others-specific permissions, or an empty array if not available.
   */
  getOthersPermissions() {
    return (this.getUserConfigurations() && this.getUserConfigurations().permissionSettings?.others) || [];
  }

  /**
   * Retrieves scopes from an array of permissions.
   * @param permissions Array of permissions to extract scopes from.
   * @returns Array of scopes extracted from the permissions.
   */
  getPermissionsScopes(permissions: IPermission[]) {
    let scopes: string[] = [];
    if (permissions) {
      scopes = permissions.map((permission) => permission.scope);
    }
    return scopes;
  }

  /**
   * Checks if the current user has a specific permission.
   * @param permission The permission to check in the format 'scope.permission'.
   * @returns True if the user has the permission, false otherwise.
   */
  hasPermission(permission: string) {
    if (!permission) return false;
    const permissionsA = permission.split('.');
    if (permissionsA.length < 3) {
      return false;
    }
    const [scope, entity, action] = permissionsA;
    let permissions: IPermission[] = [];
    switch (scope) {
      case 'self':
        permissions = this.getSelfPermissions();
        break;
      case 'ai':
        permissions = this.getAIPermissionSettings();
        break;
      case 'integrations':
        permissions = this.#getIntegrationsPermissionSettings();
        break;
      default:
        permissions = this.getOthersPermissions();
        break;
    }
    const scopes = this.getPermissionsScopes(permissions);
    const scopeIndex = scopes.indexOf(entity);
    if (scopeIndex === -1) {
      return false;
    }
    const entityPermissions = permissions[scopeIndex]?.permissions;
    return entityPermissions?.includes(action) ?? false;
  }

  /**
   * Retrieves AI-specific permission settings from user configurations.
   * @returns Array of AI-specific permissions.
   */
  private getAIPermissionSettings(): IPermission[] {
    const permissionList: IPermission[] = [];
    if (this.getUserConfigurations().aiSettings.genAiPatientSummarization) {
      permissionList.push({
        scope: 'PatientSummarization',
        label: 'Patient Summarization',
        permissions: ['Read'],
      });
    }
    if (this.getUserConfigurations().aiSettings.genAiSuggestedHcpReplies) {
      permissionList.push({
        scope: 'SuggestedHcpReplies',
        label: 'Suggested Hcp Replies',
        permissions: ['Read'],
      });
    }
    return permissionList;
  }

  /**
   * Retrieves the Integrations permission settings from user configurations.
   * It checks whether each integration is enabled and valid before including it in the permissions list.
   *
   * This function loops through the integrations section in the user configuration and filters out
   * integrations that are either invalid or disabled. It then returns the permission settings
   * for each valid integration.
   *
   * Array of Integrations-specific permissions, where each permission is an object containing scope, label, and permissions for the integration.
   */
  #getIntegrationsPermissionSettings(): IPermission[] {
    const permissions: IPermission[] = [];
    const integrations = this.getUserConfigurations().integrations;
    if (integrations) {
      for (const [key, scope] of Object.entries(integrations)) {
        if (key) {
          const isValidScope = this.#isValidIntegration(key, scope as { enabled: boolean });
          if (isValidScope) {
            permissions.push(this.#getIntegrationsScope(key));
          }
        }
      }
    }
    return permissions;
  }

  /**
   * This function checks if the integration key is valid (i.e., if it exists in the integrations section of
   * the user configuration) and validates whether the scope is enabled or not. Special logic is applied to
   * certain integrations, such as 'countly' and 'gamification', where the 'enabled' property must be true.
   *
   * @param {string} key - The integration key (e.g., 'countly', 'gamification').
   * @param {object} scope - The scope of the integration containing an 'enabled' boolean property.
   *
   *Returns true if the integration is valid and enabled, false otherwise.
   */
  #isValidIntegration(key: string, scope: { enabled: boolean }): boolean {
    const integrationKeys = Object.keys(this.getUserConfigurations().integrations) ?? [];
    if (!integrationKeys.includes(key.toLowerCase())) {
      return false;
    }
    // Specific logic for 'countly' and 'gamification'
    if (['countly', 'gamification'].includes(key.toLowerCase())) {
      return (scope as { enabled: boolean })?.enabled;
    }
    // Default for other integrations: just check if scope exists
    return !!scope;
  }

  /**
   * Generates a permission scope for a valid integration.
   *
   * This helper function constructs a permission object for the given integration key. It uses the integration
   * key to create a scope title and generates a simple permission array with a default permission of 'Read'.
   *
   * @param {string} key - The integration key (e.g., 'countly', 'gamification').
   */
  #getIntegrationsScope(key: string) {
    return {
      scope: Utils.toTitleCase(key),
      label: Utils.toTitleCase(key),
      permissions: ['Read'],
    };
  }
}

/**
 * Interface for defining permissions with labels, permissions array, and scope.
 */
export interface IPermission {
  /**
   * Optional label for the permission.
   */
  label?: string;

  /**
   * Array of permissions associated with the scope.
   */
  permissions: Array<string>;
  /**
   * Scope identifier for the permission.
   */
  scope: string;
}
