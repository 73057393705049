<div class="dialog-sm">
  <div class="hs-tab-card-title flex-between">
    @if (eventDetails && eventDetails.title) {
      <h2>{{ eventDetails.title }}</h2>
    } @else {
      -
    }

    <div class="dialog-header-icons">
      @if (!disableEditing()) {
        <i-feather name="edit-2" class="pointer" *appPermissions="'self.Calendar.Update'" (click)="edit()"></i-feather>
      }
      <i-feather name="x" class="pointer" mat-dialog-close></i-feather>
    </div>
  </div>
  <mat-dialog-content class="mat-dialog-content">
    <div class="hs-cardslist list-col-2 edit-event">
      @if (eventDetails.calendarType === 'Virtual Visit' || eventDetails.isVirtualVisit) {
        <div class="list-col-2-item">
          <p class="form-check-flex video-visit">
            <input type="checkbox" class="form-check-input" id="video-call" checked disabled />
            <label for="video-call">{{ 'videoVisit' | translate }}</label>
            <!-- <i-feather name="video" class="feather-20 clr-secondary pointer ml-2"></i-feather> -->
          </p>
          <!-- <p class="detail-value">
          <a href="https://medocity.siteportal.com/pvk-dvoy-fkd">https://medocity.siteportal.com/pvk-dvoy-fkd</a>
        </p> -->
        </div>
      }

      <div class="list-col-2-item">
        <p class="detail-label">{{ 'start' | translate }}:</p>
        @if (eventDetails.eventStart) {
          <p class="detail-value ff-bold">
            {{ eventDetails.eventStart | date: dtFormat }}
          </p>
        } @else {
          -
        }
      </div>
      <div class="list-col-2-item">
        <p class="detail-label">{{ 'end' | translate }}:</p>
        @if (eventDetails.eventEnd) {
          <p class="detail-value ff-bold">
            {{ eventDetails.eventEnd | date: dtFormat }}
          </p>
        } @else {
          -
        }
      </div>
      <div class="list-col-2-item">
        <p class="detail-label">{{ 'duration' | translate }}:</p>
        @if (eventDetails.duration) {
          <p class="detail-value ff-bold">{{ eventDetails.duration }} mins.</p>
        } @else {
          -
        }
      </div>
      <div class="list-col-2-item">
        <p class="detail-label">{{ 'attendees' | translate }}:</p>
        <div class="detail-value">
          @if (eventDetails.attendees?.member?.length <= 0 && eventDetails.attendees?.coOwner?.length <= 0) {
            <div>-</div>
          }
          @if (eventDetails.attendees?.member?.length) {
            <div>
              <span class="clr-disabled">{{ 'managed' | clientLabel }}</span>
              <span class="block-chips bg-lightRed">
                @for (member of eventDetails.attendees.member; track member) {
                  <span>
                    <span class="item text-capitalize">{{ member.fullName }}</span>
                  </span>
                }
              </span>
            </div>
          }
          @if (eventDetails.attendees?.coOwner?.length) {
            <div class="mb-0">
              <span class="clr-disabled">{{ 'manager' | clientLabel }}</span>
              <span class="block-chips bg-lightVoilet">
                @for (coOwner of eventDetails.attendees.coOwner; track coOwner) {
                  <span>
                    <span class="item text-capitalize">{{ coOwner.fullName }}</span>
                  </span>
                }
              </span>
            </div>
          }
        </div>
      </div>

      <div class="list-col-2-item align-items-center">
        <p class="detail-label">{{ 'organizer' | translate }}:</p>
        @if (eventDetails.organizer?.id) {
          <p class="detail-value">
            <span class="block-chips bg-lightGreen m-0">
              <span class="item">{{ eventDetails.organizer.fullName }}</span>
            </span>
          </p>
        } @else {
          -
        }
      </div>

      <!-- <div class="list-col-2-item">
    <p class="detail-label">{{ 'Managed' | clientLabel }}:</p>
    <p class="detail-value ff-bold">{{'mauriceCruz' | translate}}</p>
  </div> -->
      @if (!eventDetails.isVirtualVisit) {
        <div class="list-col-2-item">
          <p class="detail-label">{{ 'location' | translate }}:</p>
          @if (eventDetails.location) {
            <p class="detail-value">
              {{ eventDetails.location }}
            </p>
          } @else {
            -
          }
        </div>
      }
      <!-- <div class="list-col-2-item">
  <p class="detail-label">{{'Type' | translate}}:</p>
  <p class="detail-value ff-bold">{{'virtualVisit' | translate}}</p>
</div> -->
      <div class="list-col-2-item">
        <p class="detail-label">{{ 'notes' | translate }}:</p>
        @if (eventDetails.notes) {
          <p class="detail-value">
            {{ eventDetails.notes }}
          </p>
        } @else {
          -
        }
      </div>
    </div>
  </mat-dialog-content>
</div>
