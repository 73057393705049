<div
  class="dialog-message"
  [ngClass]="{
    'mobile-template-visible': templatesVisible,
    'mobile-contact-visible': contactsVisible
  }"
>
  <div class="new-message">
    <div class="hs-tab-card-title flex-between" [ngClass]="{ 'hide-mobile-tab': templatesVisible || contactsVisible }">
      <h2>{{ headerTitle | translate }}</h2>
      <div class="dialog-header-icons">
        <i-feather name="x" class="pointer" (click)="closeDialog()"></i-feather>
      </div>
    </div>
    <mat-dialog-content class="create-message-dialog-content">
      <div class="new-message-container">
        <div
          class="new-message-col create-mes-col-size"
          [ngClass]="{
            'hide-mobile-tab': templatesVisible || contactsVisible,
            'new-message-col-from-reply': messageData.alertId
          }"
        >
          <div class="form-group">
            <label for="" required>{{ 'to' | translate }} </label>
            <div class="form-control custom-chips-wrap" (click)="showContacts()">
              @for (participant of participantList; track participant; let i = $index) {
                <span class="custom-chips">
                  {{ participant.displayName }}&nbsp;
                  @if (!messageData.participantID) {
                    <i-feather name="x" class="pointer feather-16" (click)="removeParticipant($event, i)"></i-feather>
                  }
                </span>
              }
              @if (selectedMangedUser) {
                <span class="custom-chips">
                  {{ selectedMangedUser.displayName }}&nbsp;
                  <i-feather name="x" class="pointer feather-16" (click)="removeManagedUser($event)"></i-feather>
                </span>
              }
            </div>
            @if (clickedSend && !participantList.length && !selectedMangedUser) {
              <div class="invalid-feedback">
                {{ 'addParticipantsError' | translate }}
              </div>
            }
          </div>
          <div class="form-group">
            <label for="" required>{{ 'subject' | translate }} </label>
            <input type="text" class="form-control" [(ngModel)]="threadTopic" />
            @if (clickedSend && !threadTopic) {
              <div class="invalid-feedback">
                {{ 'noSubjectError' | translate }}
              </div>
            }
          </div>
          <div class="form-group">
            <label for="" required>{{ 'message' | translate }} </label>
            <ckeditor
              class="form-control message-thread-ck-editor"
              [editor]="ckEditor"
              [config]="ckEditorConfig"
              [(ngModel)]="message"
              (change)="onMessageEdit()"
            ></ckeditor>
            @if (clickedSend && !message) {
              <div class="invalid-feedback">
                {{ 'noMessageError' | translate }}
              </div>
            }
            @if (!selectedTemplate && firstTimeTemplateSelected) {
              <div class="warning-feedback">
                {{ 'participantChangeMessageTemplateWarning' | translate }}
              </div>
            }
          </div>

          <ng-container *appPermissions="'ai.SuggestedHcpReplies.Read'">
            @if (showSuggestedMessage) {
              <div
                class="suggested-message-wrapper"
                [ngClass]="{ 'show-suggested-message': showMobileSuggestedMessage() }"
              >
                <div class="section-dismiss-header-mobile-tab">
                  <i-feather name="x" class="pointer" (click)="showMobileSuggestedMessage.set(false)"></i-feather>
                </div>
                <app-suggested-message
                  [patientId]="messageData.participantID"
                  [mode]="'Alert'"
                  (selectedSuggestion)="useSuggestion($event)"
                ></app-suggested-message>
              </div>
            }
            @if (showResetButton) {
              <button class="btn btn-primary message-reset" (click)="resetMessage()">
                {{ 'reset' | translate }}
              </button>
            }
          </ng-container>

          @if (attachments.length) {
            <div class="attachment-icons">
              @for (attachment of attachments; track attachment; let i = $index) {
                <div class="attachment-icons-item">
                  <i-feather name="x-circle" class="attachment-cancel" (click)="removeFile(i)"></i-feather>
                  <img
                    [src]="attachment.fileIcon"
                    alt="message-attachment"
                    [class.img-attachment]="attachment.fileIcon?.toString()?.includes('https://')"
                  />
                  @if (attachment.uploadProgress) {
                    <mat-progress-spinner
                      class="attachment-icons-spinner"
                      [mode]="'determinate'"
                      [value]="attachment.uploadProgress"
                      strokeWidth="4"
                      diameter="40"
                    ></mat-progress-spinner>
                  }
                </div>
              }
            </div>
          }
        </div>
        @if (contactsVisible) {
          <div class="contact-col create-mes-col-size" [ngClass]="{ 'hide-mobile-tab': showPatientSummary() }">
            <div class="hs-tab-card-title justify-content-end view-mobile-tab">
              <i-feather name="x" class="pointer" (click)="contactsVisible = false"></i-feather>
            </div>
            <app-message-contacts
              class="new-message-contacts"
              [participantList]="participantList"
              [selectedManagedUser]="selectedMangedUser"
              (updatedManagedUserEvent)="updateManagedUser($event)"
              (updatedParticipantList)="updateParticipantList()"
              (showObservationSummary)="showObservationSummary($event)"
            ></app-message-contacts>
          </div>
        }
        @if (templatesVisible) {
          <div class="template-col create-mes-col-size">
            <div class="hs-tab-card-title justify-content-end view-mobile-tab">
              <i-feather name="x" class="pointer" (click)="templatesVisible = false"></i-feather>
            </div>
            <app-message-template
              [fromCreateMessage]="true"
              (templateSelected)="selectTemplate($event)"
            ></app-message-template>
          </div>
        }
        @if (showPatientSummary()) {
          <div class="summary-col create-mes-col-size">
            <div class="section-dismiss-header-mobile-tab">
              <i-feather name="x" class="pointer" (click)="showPatientSummary.set(false)"></i-feather>
            </div>
            <h3 class="patient-summary-title">
              <div class="patient-summary-title-badge">
                <span class="patient-summary-title-text">{{ 'recentObservationSummary' | translate }}</span>
                <img class="beta-badge" src="assets/images/icons/beta-icon.svg" alt="beta" />
              </div>
              <i-feather class="feather-20 pointer" name="refresh-cw" (click)="reloadObservationSummary()"></i-feather>
            </h3>
            <app-observation-summarization
              [patientId]="patientUserId()"
              class="new-message-summary"
            ></app-observation-summarization>
          </div>
        }
      </div>
    </mat-dialog-content>
    <div class="new-message-footer" [ngClass]="{ 'hide-mobile-tab': templatesVisible || contactsVisible }">
      <div class="new-message-icons">
        <input type="file" #fileInput id="file" class="d-none" multiple (change)="onFileSelected($event)" />
        <i-feather name="paperclip" class="feather-20 pointer" (click)="fileInput.click()"></i-feather>
        <i-feather name="camera" class="feather-20 pointer" (click)="openCamera()"></i-feather>
        <i-feather name="file-text" class="feather-20 pointer" (click)="showTemplates()"></i-feather>
        @if (messageData.alertId) {
          <i-feather
            name="suggested-message"
            class="suggested-message-icon-mobile-tab pointer"
            [ngClass]="{ 'hide-suggested-icon': !showSuggestedMessage }"
            (click)="showMobileSuggestedMessage.set(true)"
          ></i-feather>
        }
      </div>
      <button type="button" class="btn btn-primary" [disabled]="fileUploadsInProgress" (click)="createThread()">
        {{ 'send' | translate }}
      </button>
    </div>
    <ng-container *appPermissions="'ai.PatientSummarization.Read'">
      @if (messageData.alertId) {
        <h3 class="patient-summary-title patient-summary-trigger-mobile-tab" (click)="showPatientSummary.set(true)">
          <div class="patient-summary-title-badge">
            <span class="patient-summary-title-text">{{ 'recentObservationSummary' | translate }}</span>
            <img class="beta-badge" src="assets/images/icons/beta-icon.svg" alt="beta" />
          </div>
          <i-feather name="chevron-right" class="pointer"></i-feather>
        </h3>
      }
    </ng-container>
  </div>
</div>
