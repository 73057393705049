import { Component, inject, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { FeatherModule } from 'angular-feather';
import { firstValueFrom } from 'rxjs';
import { MyTicket } from '../../entities/support-entities';
import { SupportService } from '../../support.service';

@Component({
  selector: 'app-support-icon',
  standalone: true,
  imports: [FeatherModule, RouterLink],
  templateUrl: './support-icon.component.html',
  styleUrl: './support-icon.component.scss',
})
export class SupportIconComponent implements OnInit {
  /**
   * The support service instance injected into the component.
   * This service is used to fetch user tickets and manage unread ticket state.
   *
   */
  #supportService = inject(SupportService);
  /**
   * Lifecycle hook that initializes the component.
   * It fetches the support tickets and updates the unread tickets count.
   */
  public async ngOnInit(): Promise<void> {
    const response = await this.fetchTicketsByExternalId();
    this.#setUnreadTickets(response.tickets);
  }
  /**
   * Fetches the support tickets associated with a user's external ID.
   * This method uses the `SupportService` to get the tickets and returns the result.
   */
  public async fetchTicketsByExternalId() {
    const externalId = this.#supportService.getUsertId();
    return await firstValueFrom(this.#supportService.getTicketsByExternalId(externalId));
  }
  /**
   * Returns the count of unread support tickets.
   * This method checks the unread ticket list in the `SupportService` and returns the length.
   */
  public ticketsCounter() {
    return this.#supportService.getUnreadTickets()?.length || 0;
  }
  /**
   * Sets the unread tickets in the `SupportService`.
   * If there are tickets, they are set; otherwise, an empty array is set.
   */
  #setUnreadTickets(tickets: MyTicket[]) {
    this.#supportService.unreadTickets.set(tickets?.length ? tickets : []);
  }
}
