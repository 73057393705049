import { inject, Injectable } from '@angular/core';
import Vitals from 'src/assets/lookup/vitals.json';
import { LookUpVital } from '../../health-summary/vitals/entities/vital-signs';
import { PermissionsService } from '../../permissions/services/permissions.service';
import { Utils } from '../utils/utils';

/**
 * UnitPreferenceService provides methods to manage unit preferences for vital signs.
 * It handles lookup data for vital signs and converts values between metric and standard units.
 */
@Injectable({
  providedIn: 'root',
})
export class UnitPreferenceService {
  /**
   * Inject PermissionsService instance to handle permissions or accessing content on the basis of permissions.
   */
  #permissionsService = inject(PermissionsService);

  /**
   * Constructs an instance of UnitPreferenceService.
   */
  constructor() {}
  /**
   * Retrieves the lookup data for vital signs.
   */
  getLookUpVitals(): Record<string, LookUpVital> {
    return Utils.copy(Vitals);
  }

  /**
   * Gets the preferred unit for blood glucose measurement.
   * @returns The unit of measurement for blood glucose, defaults to 'mg/dl'.
   */
  getBloodGlucoseUnit(): string {
    return this.#permissionsService.getUserConfigurations()?.BloodGlucoseUnit || 'mg/dl';
  }

  /**
   * Gets the user's preferred unit for measurement.
   * @returns The unit preference, defaults to 'standard'.
   */
  getUnitPreference(): string {
    return this.#permissionsService.getUserConfigurations()?.UnitPreference || 'standard';
  }

  /**
   * Determines the unit of measurement based on the vital key and method.
   */
  getUnitByVitalKey(vitalKey: string, method?: string, unit?: string): string {
    const unitPreference = method && method?.toLowerCase() === 'post' ? 'metric' : this.getUnitPreference();
    let vUnit = '';
    if (vitalKey) {
      switch (vitalKey) {
        case 'Weight':
          vUnit = unitPreference === 'standard' ? 'lbs' : 'kg';
          break;
        case 'Temperature':
        case 'SkinTemperature':
          vUnit = unitPreference === 'standard' ? 'F' : 'C';
          break;
        case 'BloodSugar':
          vUnit = method && method?.toLowerCase() === 'post' ? 'mg/dl' : this.getBloodGlucoseUnit();
          break;
        default:
          const key = vitalKey?.toLowerCase() === 'bloodpressure' ? 'BloodPressure_Systolic' : vitalKey;
          vUnit = this.getLookUpVitals()?.[key]?.unit || unit || '';
          break;
      }
    }
    return vUnit ? vUnit : '';
  }

  /**
   * Converts a value from metric to standard units based on the vital key.
   */
  convertMetricToStandard(vitalKey: string, value: number | string | null) {
    let convertedValue: number | string | null = value;
    if (vitalKey) {
      switch (vitalKey) {
        case 'Weight':
          if (this.getUnitPreference() === 'standard') {
            convertedValue = value ? Number(value) * 2.20462 : value;
          }
          break;
        case 'Temperature':
        case 'SkinTemperature':
          if (this.getUnitPreference() === 'standard') {
            convertedValue = value ? (Number(value) * 9) / 5 + 32 : value;
          }
          break;
        case 'BloodSugar':
          if (this.getBloodGlucoseUnit() === 'mmol/L') {
            convertedValue = value ? Number(value) / 18.018 : value;
          }
          break;
        default:
          convertedValue = value;
          break;
      }
    }
    return Number(Number(convertedValue)?.toFixed(1));
  }

  /**
   * Converts a value from standard to metric units based on the vital key.
   */
  convertStandardToMetric(vitalKey: string, value: number | string | null) {
    let convertedValue: number | string | null = value;
    if (vitalKey) {
      switch (vitalKey) {
        case 'Weight':
          if (this.getUnitPreference() === 'standard') {
            convertedValue = value ? Number(value) / 2.20462 : value;
          }
          break;
        case 'Temperature':
        case 'SkinTemperature':
          if (this.getUnitPreference() === 'standard') {
            convertedValue = value ? ((Number(value) - 32) * 5) / 9 : value;
          }
          break;
        case 'BloodSugar':
          if (this.getBloodGlucoseUnit() === 'mmol/L') {
            convertedValue = value ? Number(value) * 18.018 : value;
          }
          break;
        default:
          convertedValue = value;
          break;
      }
    }
    return Number(Number(convertedValue)?.toFixed(1));
  }
}
