import { inject, Injectable } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpService } from '../../shared/services/http.service';
import { APISuccess, ObservationSummary, TimePeriodObservationSummary } from '../entities/observation-summary.entities';

/**
 * Service for observation summary.
 */
@Injectable({
  providedIn: 'root',
})
export class ObservationSummaryService {
  /**
   * Base URL for API requests, retrieved from environment configuration.
   */
  #baseUrl: string = environment.url.apiHost;
  /**
   * API version used for API requests, retrieved from environment configuration.
   */
  #apiVersion: string = environment.url.version;
  /**
   * Injected service for message APIs
   */
  #http = inject(HttpService);

  /**
   * Get Patient Observation Summary
   * @param userId - User Id of Patient
   * @returns - Patient Summary
   */
  public getPatientSummary(userId: string, refresh = false): Observable<ObservationSummary> {
    const url = `${this.#baseUrl}${this.#apiVersion}/ai/observationSummarization/user/${userId}${refresh ? '?state=refresh' : ''}`;
    return this.#http.get(url, {}, true);
  }

  /**
   * Send User Feedback on Patient Summary
   * @param userId - User Id of Patient
   * @param helpful - Whether Patient Summary was helpful or not
   * @returns - Thank you message to be displayed
   */
  public sendFeedbackOnPatientSummary(userId: string, helpful: boolean, feedback: string): Promise<APISuccess> {
    const url = `${this.#baseUrl}${this.#apiVersion}/ai/observationSummarization/user/${userId}`;
    return firstValueFrom(
      this.#http.post(
        url,
        {
          helpful,
          feedback,
        },
        {},
        false,
      ),
    );
  }

  public getObservationSummaryBasedOnTimePeriod(userId: string): Promise<TimePeriodObservationSummary> {
    const url = `${this.#baseUrl}${this.#apiVersion}/ai/trends/user/${userId}?state=refresh`;
    return firstValueFrom(this.#http.get(url, {}, true));
  }
}
