import { computed, inject, Injectable, Signal, signal, WritableSignal } from '@angular/core';
import Highcharts, { SelectEventObject } from 'highcharts';
import { BehaviorSubject } from 'rxjs';
import {
  Anomaly,
  GraphQueryParams,
  Trend,
  VitalData,
  VitalGraph,
  VitalGraphData,
} from '../../health-summary/vitals/entities/vital-signs';
import { TranslatePipe } from '../../shared/pipes/translate.pipe';
import { UnitPreferenceService } from '../../shared/services/unit-preference.service';
import { Utils } from '../../shared/utils/utils';
import {
  AREA_SERIES_OPTIONS,
  BAR_CHART_OPTIONS,
  BAR_SERIES_OPTIONS,
  BUBBLE_SERIES_OPTIONS,
  COLUMN_SERIES_OPTIONS,
  DATE_TIME_CHART_OPTIONS,
  DEFAULT_CHART_OPTIONS,
  DEFAULT_SERIES_OPTIONS,
  PIE_CHART_OPTIONS,
  PIE_SERIES_OPTIONS,
} from '../constants/chart-options';
import { ChartCategory, ChartTypes } from '../constants/chart-types';
import {
  CHART_GROUP_BY_OPTIONS,
  CHART_TOOLTIP_DATE_TIME_FORMAT,
  COLORS,
  DEFAULT_COLOR,
  DEFAULT_TREND_COLOR,
  RANGE_BUTTONS,
  SEVERITY_COLORS,
} from '../constants/charts.constant';
import {
  AlertReported,
  AssessmentChart,
  AssessmentChartCollection,
  ChartData,
  ChartDataPoint,
  Series,
  SeriesOption,
  SeveritySeries,
  TableData,
} from '../entities/charts';

/**
 * Service for managing chart-related functionalities.
 * Provides methods to retrieve chart data, handle chart options,
 * and manipulate chart types and view configurations.
 */
@Injectable({
  providedIn: 'root',
})
export class ChartService {
  /**
   * The current view type for the chart.
   */
  public chartViewType: WritableSignal<string> = signal('sm');

  /**
   * Computes the current chart view type.
   */
  public getChartViewType: Signal<string> = computed(() => {
    return this.chartViewType();
  });

  /**
   * BehaviorSubject to trigger chart refresh.
   */
  public refreshChart$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /**
   * The type of vital chart to display.
   */
  #vitalChartType = 'trends';

  /**
   * Query parameters for graph requests.
   */
  #graphQueryParams!: GraphQueryParams;

  /**
   * Service to manage unit preferences.
   */
  #unitPreferenceService = inject(UnitPreferenceService);

  /**
   * Pipe for translating text.
   */
  #translatePipe = inject(TranslatePipe);

  /**
   * Creates an instance of ChartService.
   */
  constructor() {}
  /**
   * Retrieves chart config.
   * @param data - Data for the chart.
   * @param category - Category of the chart.
   * @param type - Optional type of the chart.
   * @returns Options object for chart.
   */
  public getChartConfig(data: VitalGraph | AssessmentChart | unknown, category: string = '', vitalKey?: string) {
    return this.#getChartOptions(category, data, vitalKey);
  }
  /**
   * Retrieves default Button ranges.
   * @returns Default Button ranges.
   */
  public getButtonRanges() {
    return Utils.copy(RANGE_BUTTONS);
  }
  /**
   * Retrieves default group by options.
   * @returns Default group by options.
   */
  public getGroupByOptions() {
    return Utils.copy(CHART_GROUP_BY_OPTIONS);
  }

  /**
   * Retrieves the vital chart type.
   * @returns {string} The current vital chart type.
   */
  public getVitalChartType() {
    return this.#vitalChartType || 'trends';
  }

  /**
   * Retrieves table data for a given chart category.
   */
  public getChartTableData(data: VitalGraph | AssessmentChart | unknown, category: string, vitalKey = '') {
    let tableData: TableData[] = [];
    if (category) {
      switch (category?.toLowerCase()) {
        case ChartCategory.VITALS:
          tableData = this.#getVitalChartTableData(data as VitalGraph, vitalKey);
          break;
        case ChartCategory.HEALTH_TRACKER:
          tableData = this.#getAssessmentChartTableData(data as AssessmentChart);
          break;
        case ChartCategory.ASSESSMENT:
          tableData = this.#getAssessmentChartTableData(data as AssessmentChart);
          break;
        default:
          //tableData = this.#getDefaultChartOptions();
          break;
      }
    }
    return tableData;
  }
  /**
   * get default chart query params to get Graph.
   */
  public getDefaultGraphQueryParams(vitalKey = '') {
    return {
      startDate: Utils.convertLocalToUTC(Utils.subtractDate(new Date(), 30)),
      endDate: Utils.convertLocalToUTC(new Date()),
      interval: '1H',
      vital: vitalKey,
      groupBy: 'automatic',
    };
  }
  /**
   * get default chart query params to get Graph.
   */
  public getGraphQueryParams() {
    return this.#graphQueryParams ? this.#graphQueryParams : this.getDefaultGraphQueryParams();
  }

  /**
   * Sets the vital chart type.
   */
  public setVitalChartType(type = 'trends') {
    this.#vitalChartType = type;
  }
  /**
   * Set query params to get Graph.
   * @param params - Refers to the vital query params.
   */
  public setGraphQueryParams(params: GraphQueryParams) {
    const startDate = params?.startDate
      ? Utils.convertLocalToUTC(new Date(params?.startDate))
      : Utils.convertLocalToUTC(Utils.subtractDate(new Date(), 30));
    const endDate = params?.endDate
      ? Utils.convertLocalToUTC(new Date(params?.endDate))
      : Utils.convertLocalToUTC(new Date());
    this.#graphQueryParams = {
      startDate: startDate,
      endDate: endDate,
      vital: params?.vital || '',
      groupBy: params?.groupBy,
      interval: params?.groupBy === 'automatic' ? this.getIntervalByDays(startDate, endDate) : params?.groupBy,
    };
  }

  /**
   * Retrieves the automatic group-by option key based on the current graph query parameters.
   */
  public getAutomaticGroupByOptionKey() {
    const interval = this.getGraphQueryParams()?.interval || this.getDefaultGraphQueryParams()?.interval;
    let key = '';
    switch (interval) {
      case '1MI':
        key = 'automaticByMinutes';
        break;
      case '1H':
        key = 'automaticByHourly';
        break;
      case '1D':
        key = 'automaticByDaily';
        break;
      case '1MO':
        key = 'automaticByMonthly';
        break;
      default:
        key = 'automatic';
        break;
    }
    return key;
  }
  /**
   * get interval for query params.
   * @param startDate Start Date.
   * @param endDate End Date.
   * @returns interval.
   */
  public getIntervalByDays(startDate: Date | string, endDate: Date | string) {
    const noOfDays = Utils.getNoOfDays(new Date(startDate), new Date(endDate));
    let interval = '1H';
    if (noOfDays <= 0) {
      interval = '1MI';
    } else if (noOfDays >= 1 && noOfDays <= 3) {
      interval = '1MI';
    } else if (noOfDays >= 3 && noOfDays <= 180) {
      interval = '1H';
    } else if (noOfDays >= 180 && noOfDays <= 365) {
      interval = '1D';
    } else if (noOfDays > 365) {
      interval = '1MO';
    } else {
      interval = '1D';
    }
    return interval;
  }
  /**
   * Retrieves default chart options.
   * @returns Default options object for Highcharts.
   */
  #getDefaultChartOptions() {
    return Utils.copy(DEFAULT_CHART_OPTIONS);
  }
  /**
   * Retrieves default chart options.
   * @returns Default options object for Highcharts.
   */
  #getDateTimeChartOptions() {
    return Utils.copy(DATE_TIME_CHART_OPTIONS);
  }
  /**
   * Retrieves pie chart options.
   * @returns Default pie options object for Highcharts.
   */
  #getPieChartOptions() {
    return Utils.copy(PIE_CHART_OPTIONS);
  }
  /**
   * Retrieves pie chart options.
   * @returns Default pie options object for Highcharts.
   */
  #getBarChartOptions() {
    return Utils.copy(BAR_CHART_OPTIONS);
  }
  /**
   * Retrieves severity chart series.
   * @returns Series fot severity chart.
   */
  #getSeveritySeries() {
    const severitySeries: SeveritySeries[] = [
      {
        data: [],
        key: 'none',
        color: SEVERITY_COLORS[0],
        name: this.#translatePipe.transform('none') || 'None',
      },
      {
        data: [],
        key: 'mild',
        color: SEVERITY_COLORS[1],
        name: this.#translatePipe.transform('mild') || 'Mild',
      },
      {
        data: [],
        key: 'moderate',
        color: SEVERITY_COLORS[2],
        name: this.#translatePipe.transform('moderate') || 'Moderate',
      },
      {
        data: [],
        key: 'severe',
        color: SEVERITY_COLORS[3],
        name: this.#translatePipe.transform('severe') || 'Severe',
      },
      {
        data: [],
        key: 'verySevere',
        color: SEVERITY_COLORS[4],
        name: this.#translatePipe.transform('verySevere') || 'Very Severe',
      },
    ];
    return severitySeries;
  }

  /**
   * Updates query parameters based on zooming event.
   */
  #setQueryParamsOnZooming(e: SelectEventObject) {
    if (e.resetSelection) {
    } else {
      const params = {
        startDate: new Date(e.xAxis[0].min),
        endDate: new Date(e.xAxis[0].max),
        vital: this.getGraphQueryParams()?.vital,
        groupBy: 'automatic',
      };
      this.setGraphQueryParams(params);
      this.refreshChart$.next(true);
    }
    return false;
  }

  /**
   * Retrieves the date format for chart tooltips.
   */
  #getChartTooltipDateFormat() {
    return CHART_TOOLTIP_DATE_TIME_FORMAT;
  }

  /**
   * Retrieves options for the chart based on category and data.
   */
  #getChartOptions(category: string, chart: VitalGraph | AssessmentChart | unknown, vitalKey?: string) {
    let options;
    if (category) {
      switch (category?.toLowerCase()) {
        case ChartCategory.VITALS:
          options = this.#getVitalChartOptions(vitalKey);
          break;
        case ChartCategory.HEALTH_TRACKER:
          options = this.#getAssessmentChartOptions(chart as AssessmentChart);
          break;
        case ChartCategory.ASSESSMENT:
          options = this.#getAssessmentChartOptions(chart as AssessmentChart);
          break;
        case ChartCategory.ALERT_SEVERITY:
          options = this.#getAlertSeverityChartOptions();
          break;
        case ChartCategory.LABS:
          options = this.#getDefaultChartOptions();
          break;
        case ChartCategory.ALERT_MOST_REPORTED:
          options = this.#getAlertMostReportedChartOptions();
          break;
        default:
          options = this.#getDefaultChartOptions();
          break;
      }
    }
    options.series = this.#getChartSeries(chart, category, vitalKey);
    return options;
  }

  /**
   * Retrieves the series for the chart based on category and data.
   */
  #getChartSeries(data: VitalGraph | AssessmentChart | unknown, category: string, vitalKey?: string) {
    let series: Series[] = [];
    if (category) {
      switch (category?.toLowerCase()) {
        case ChartCategory.VITALS:
          series = this.#getVitalChartSeries(data as VitalGraph, vitalKey);
          break;
        case ChartCategory.HEALTH_TRACKER:
          break;
        case ChartCategory.ASSESSMENT:
          series = this.#getAssessmentChartSeries(data as AssessmentChart);
          break;
        case ChartCategory.ALERT_SEVERITY:
          series = this.#getAlertSeverityChartSeries(data as Record<string, number>);
          break;
        case ChartCategory.ALERT_MOST_REPORTED:
          series = this.#getAlertMostReportedChartSeries(data as AlertReported[]);
          break;
        default:
          break;
      }
    }
    return series;
  }

  /**
   * Transforms chart data into a format suitable for plotting.
   */
  #getChartData(data: ChartData[]) {
    if (data?.length) {
      return data?.map((d: ChartData) => {
        return { x: d.date, y: d.value, tooltip: d.tooltip || '' };
      });
    }
    return [];
  }

  /**
   * Transforms chart data into a range format suitable for plotting.
   */
  #getChartDataRanges(data: ChartData[]) {
    if (data?.length) {
      return data?.map((d: ChartData) => {
        return { x: d.date, low: d.low, high: d.high };
      });
    }
    return [];
  }

  /**
   * Retrieves series options by merging user-defined options with default options based on type.
   */
  #getSeriesOptions(options: SeriesOption) {
    if (options) {
      const seriesOptions = this.#getChartSeriesOptions(options?.type);
      return { ...options, ...seriesOptions };
    }
    return options;
  }

  /**
   * Retrieves default series options based on the specified chart type.
   */
  #getChartSeriesOptions(type: string = '') {
    let options;
    switch (type?.toLowerCase()) {
      case ChartTypes.PIE:
        options = Utils.copy(PIE_SERIES_OPTIONS);
        break;
      case ChartTypes.COLUMN_RANGE:
      case ChartTypes.COLUMN:
        options = Utils.copy(COLUMN_SERIES_OPTIONS);
        break;
      case ChartTypes.BAR:
        options = Utils.copy(BAR_SERIES_OPTIONS);
        break;
      case ChartTypes.LINE:
      case ChartTypes.SPLINE:
        options = Utils.copy(DEFAULT_SERIES_OPTIONS);
        break;
      case ChartTypes.SPLINE:
        options = Utils.copy(DEFAULT_SERIES_OPTIONS);
        break;
      case ChartTypes.AREA_SPLINE_RANGE:
        options = Utils.copy(AREA_SERIES_OPTIONS);
        break;
      case ChartTypes.BUBBLE:
        options = Utils.copy(BUBBLE_SERIES_OPTIONS);
        break;
      default:
        options = Utils.copy(DEFAULT_SERIES_OPTIONS);
        break;
    }
    return options;
  }

  /**
   * Computes the zones for vital series based on data and color code.
   */
  #getVitalSeriesZones(data: VitalData[], colorCode: string | undefined) {
    const zones = [
      {
        value: Math.min(...data?.map((d) => Number(d.low))),
        color: 'red',
      },
      {
        value: Math.max(...data?.map((d) => Number(d.low))),
        color: colorCode || DEFAULT_COLOR,
      },
      {
        color: 'red',
      },
    ];
    return zones;
  }

  /**
   * Retrieves the assessment chart series based on chart collections.
   */
  #getAssessmentChartSeries(chart: AssessmentChart) {
    let seriesIndex = 0;
    const series = this.#getAssessmentSeriesOptions(chart);
    Object.entries(chart.collections).forEach(([, collection], cIndex) => {
      if (collection?.length) {
        collection?.forEach((c) => {
          if (
            chart?.collectionType?.toLowerCase() === ChartCategory.HEALTH_TRACKER &&
            chart?.type?.toLowerCase() === ChartTypes.BUBBLE
          ) {
            seriesIndex = Number(c.value);
          } else {
            seriesIndex = cIndex;
          }
          const point: ChartDataPoint = {
            x: new Date(c.date)?.getTime(),
            y: Number(c.value),
            z: Number(c.value),
            tooltip: c?.tooltip?.replace(/\[:BulletPoint]/gi, '\n• '),
          };
          if (chart?.type?.toLowerCase() !== ChartTypes.BUBBLE) {
            delete point.z;
          }
          series?.[seriesIndex]?.data?.push(point);
        });
      }
    });
    return series;
  }

  /**
   * Retrieves assessment series options based on the chart details and severity series.
   */
  #getAssessmentSeriesOptions(chart: AssessmentChart) {
    let chartSeries: Series[] = [];
    const severitySeries = this.#getSeveritySeries();
    if (
      chart?.collectionType?.toLowerCase() === ChartCategory.HEALTH_TRACKER &&
      chart?.type?.toLowerCase() === ChartTypes.BUBBLE &&
      chart?.collectionChart
    ) {
      chartSeries = severitySeries.map((severity) => {
        const severityOptions = this.#getSeriesOptions({
          name: severity.name,
          type: chart?.type?.toLowerCase(),
          data: [],
          color: severity.color,
        });
        severity = { ...severity, ...severityOptions };
        return severity;
      });
    } else {
      if (Object.keys(chart?.collections)?.length) {
        const series: Series[] = [];
        Object.entries(chart.collections).forEach(([cKey], cIndex) => {
          const seriesOptions = this.#getSeriesOptions({
            name: cKey.charAt(0).toUpperCase() + cKey.slice(1),
            type: chart?.type?.toLowerCase(),
            data: [],
            color: COLORS[cIndex],
          });
          series.push(seriesOptions);
        });
        chartSeries = series;
      }
    }
    return chartSeries;
  }

  /**
   * Retrieves options for the assessment chart based on the chart details.
   */
  #getAssessmentChartOptions(chart: AssessmentChart) {
    const options = this.#getExtendedDateTimeChartOptions();
    const severityLegends = this.#getSeveritySeries().map((severity: SeveritySeries, index: number) => {
      return { name: severity.name, index: index };
    });
    if (
      chart?.chartCategory?.toLowerCase() === ChartCategory.ASSESSMENT ||
      chart?.collectionType?.toLowerCase() === ChartCategory.HEALTH_TRACKER
    ) {
      if ((chart && chart.collections && Object.keys(chart.collections).length > 1) || chart.collectionChart) {
        options.legend.enabled = true;
      } else {
        options.legend.enabled = false;
      }
      options.yAxis.labels.enabled =
        chart?.chartType?.toLowerCase() === ChartTypes.BUBBLE && chart.collectionChart ? false : true;
    }
    options.yAxis.title.text = chart.yLabel;
    options.tooltip.formatter = function () {
      return `<b>${Highcharts.dateFormat(
        '%b %d, %y %H:%M %p',
        this.x,
      )}</b><p style="white-space:pre-wrap;">${this.point.tooltip}</p>`;
    };
    options.legend.labelFormatter = function () {
      if (
        (chart?.chartCategory?.toLowerCase() === ChartCategory.ASSESSMENT ||
          chart?.collectionType?.toLowerCase() === ChartCategory.HEALTH_TRACKER) &&
        chart.collectionChart
      ) {
        let legends = '';
        if (chart?.chartType?.toLowerCase() === ChartTypes.LINE) {
          severityLegends.map((legend: { name: string; index: number }) => {
            legends = legends + `${legend.index}: ${legend.name} `;
            return legend;
          });
          if (this?.legendItem?.symbol) {
            this.legendItem.symbol.hide();
          }
          if (this?.legendItem?.line) {
            this.legendItem.line.hide();
          }
          return legends;
        } else {
          return this.name;
        }
      } else {
        return this.name;
      }
    };
    return options;
  }

  /**
   * Retrieves table data for the assessment chart based on its collections.
   */
  #getAssessmentChartTableData(chart: AssessmentChart) {
    const tableData: TableData[] = [];
    if (chart?.collections) {
      Object.entries(chart.collections)?.forEach(([, values]) => {
        values?.forEach((v: AssessmentChartCollection) => {
          const value = !chart.collectionChart ? v.tooltip : v.value;
          tableData.push({ value: value, date: v.date });
        });
      });
    }
    return tableData;
  }

  /**
   * Transforms vital data into chart data format based on the specified vital key.
   */
  #getVitalChartData(data: Trend[] | Anomaly[], vitalKey: string) {
    const chartData = data?.map((t: VitalGraphData) => {
      const value = Number(this.getVitalChartType()?.toLowerCase() === 'trends' ? t.avgValue : t.value);
      const low = Number(this.getVitalChartType()?.toLowerCase() === 'trends' ? t.minValue : t.lower);
      const high = Number(this.getVitalChartType()?.toLowerCase() === 'trends' ? t.maxValue : t.upper);
      return {
        value: this.#unitPreferenceService.convertMetricToStandard(vitalKey, value),
        date: new Date(t.timestamp)?.getTime(),
        low: this.#unitPreferenceService.convertMetricToStandard(vitalKey, low) ?? null,
        high: this.#unitPreferenceService.convertMetricToStandard(vitalKey, high) ?? null,
        unit: this.#unitPreferenceService.getUnitByVitalKey(vitalKey),
      };
    });
    return chartData;
  }

  /**
   * Retrieves vital chart table data based on the provided vital graph and vital key.
   */
  #getVitalChartTableData(data: VitalGraph, vitalKey: string) {
    const vitalChartdDataKey = this.getVitalChartType();
    let bpData: VitalData[] = [];
    let tableData: TableData[] = [];
    if (data && vitalKey) {
      if (vitalKey?.toLowerCase() === 'bloodpressure') {
        Object.entries(data).forEach(([key, d]) => {
          if (d?.[vitalChartdDataKey]?.length) {
            bpData = [
              ...bpData,
              ...this.#getVitalChartData(d?.[vitalChartdDataKey], vitalKey).map((bp) => {
                return { ...bp, ...{ bpKey: key } };
              }),
            ];
          }
        });
        Object.entries(Utils.groupBy(bpData, 'date')).forEach(([k, d]) => {
          tableData.push({
            value: `${(d as VitalData[]).map((c) => c?.value).join('/')} ${this.#unitPreferenceService.getUnitByVitalKey(vitalKey)}`,
            low: `${(d as VitalData[]).map((c) => c?.low).join('/')} ${this.#unitPreferenceService.getUnitByVitalKey(vitalKey)}`,
            high: `${(d as VitalData[]).map((c) => c?.high).join('/')} ${this.#unitPreferenceService.getUnitByVitalKey(vitalKey)}`,
            date: Number(k),
          });
        });
      } else {
        Object.entries(data).forEach(([key, d]) => {
          if (d && key?.toLowerCase() === vitalChartdDataKey?.toLowerCase())
            tableData = this.#getVitalChartData(d, vitalKey).map((v) => {
              return {
                value: `${v.value} ${this.#unitPreferenceService.getUnitByVitalKey(vitalKey)}`,
                low: `${v.low} ${this.#unitPreferenceService.getUnitByVitalKey(vitalKey)}`,
                high: `${v.high} ${this.#unitPreferenceService.getUnitByVitalKey(vitalKey)}`,
                date: Number(v.date),
              };
            });
        });
      }
    }
    return tableData;
  }

  /**
   * Retrieves the series data for the vital chart based on the provided vital graph and vital key.
   */
  #getVitalChartSeries(data: VitalGraph, vitalKey: string | undefined) {
    const series: Series[] = [];
    const seriesDataKey = [ChartTypes.AVERAGE, ChartTypes.RANGE];
    const dataKey = this.getVitalChartType();
    if (vitalKey && data) {
      if (vitalKey?.toLowerCase() === 'bloodpressure') {
        Object.entries(data).forEach(([key, d]) => {
          if (d?.[dataKey]?.length) {
            const colorCode = key === 'BloodPressure_Systolic' ? COLORS[0] : COLORS[1];
            seriesDataKey?.forEach((sDKey: string) => {
              const seriesOptions = this.#getVitalSeriesOptions(d?.[dataKey], key, sDKey, colorCode);
              series.push(seriesOptions);
            });
          }
        });
      } else {
        const d = dataKey?.toLowerCase() === 'trends' ? data?.trends : data?.anomaly;
        if (d?.length) {
          seriesDataKey?.forEach((sDKey: string) => {
            const colorCode = sDKey === ChartTypes.AVERAGE ? DEFAULT_TREND_COLOR : DEFAULT_COLOR;
            const seriesOptions = this.#getVitalSeriesOptions(d, vitalKey, sDKey, colorCode);
            series.push(seriesOptions);
          });
        }
      }
    }
    return series;
  }

  /**
   * Retrieves series options for the vital chart based on the provided data and vital key.
   */
  #getVitalSeriesOptions(data: Trend[] | Anomaly[], vitalKey: string, seriesType?: string, colorCode?: string) {
    const chartData = this.#getVitalChartData(data, vitalKey);
    const seriesOptions = this.#getSeriesOptions({
      name: vitalKey,
      type:
        seriesType?.toLowerCase() === ChartTypes.RANGE
          ? this.getVitalChartType()?.toLowerCase() === 'trends'
            ? ChartTypes.COLUMN_RANGE
            : ChartTypes.AREA_SPLINE_RANGE
          : ChartTypes.SPLINE,
      data:
        seriesType?.toLowerCase() === ChartTypes.RANGE
          ? this.#getChartDataRanges(chartData)
          : this.#getChartData(chartData),
      color: colorCode || DEFAULT_COLOR,
      zIndex: seriesType?.toLowerCase() === ChartTypes.RANGE ? 0 : 1,
      custom: {
        data: chartData,
      },
    });
    if (this.getVitalChartType()?.toLowerCase() === 'anomaly' && seriesType?.toLowerCase() === ChartTypes.AVERAGE) {
      seriesOptions.zones = this.#getVitalSeriesZones(chartData, colorCode);
    }
    return seriesOptions;
  }

  /**
   * Retrieves options for the vital chart, including tooltip and legend configurations.
   */
  #getVitalChartOptions(vitalKey?: string) {
    const translatePipe = this.#translatePipe;
    const options = this.#getExtendedDateTimeChartOptions();
    const format = this.#getChartTooltipDateFormat();
    options.tooltip.formatter = function () {
      const point = this.point?.series?.options?.custom?.data?.[this.point.index];
      const dateFormat =
        new Date(this.x)?.getUTCHours() === 0 && new Date(this.x)?.getUTCMinutes() === 0 ? format.day : format.hour;
      return `<b>${Highcharts.dateFormat(dateFormat, new Date(this.x).getTime())}</b>
            </br>${this.point.series.name}
            </br>${translatePipe.transform('average')} - ${this.point.y} ${point.unit}
            </br>${translatePipe.transform('minimum')} - ${point.low} ${point.unit}
            </br>${translatePipe.transform('maximum')} - ${point.high} ${point.unit}`;
    };
    options.legend.enabled = vitalKey?.toLowerCase() === 'bloodpressure' ? true : false;
    return options;
  }

  /**
   * Retrieves options for the alert severity chart.
   */
  #getAlertSeverityChartOptions() {
    const options = this.#getPieChartOptions();
    return options;
  }

  /**
   * Retrieves series data for the alert severity chart based on severity counts.
   */
  #getAlertSeverityChartSeries(data: Record<string, number>) {
    const series: Series[] = [];
    const seriesOptions = this.#getSeriesOptions({
      name: ChartCategory.ALERT_SEVERITY,
      type: ChartTypes.PIE,
      data: this.#getSeveritySeries().map((severity, index) => {
        const key = `severity${index}`;
        return { y: data?.[key], name: severity.name, color: severity.color };
      }),
    });
    series.push(seriesOptions);
    return series;
  }

  /**
   * Retrieves options for the alert most reported chart, including bar chart configurations.
   */
  #getAlertMostReportedChartOptions() {
    const options = this.#getDefaultChartOptions();
    const barChartOptions = this.#getBarChartOptions();
    barChartOptions.xAxis.categories = ['HealthTracker', 'Vital'];
    return { ...options, ...barChartOptions };
  }

  /**
   * Retrieves series data for the alert most reported chart based on reported alerts.
   */
  #getAlertMostReportedChartSeries(data: AlertReported[]) {
    const series: Series[] = [];
    const seriesOptions = this.#getSeriesOptions({
      name: ChartCategory.ALERT_MOST_REPORTED,
      type: ChartTypes.BAR,
      data: data.map((d: AlertReported, index: number) => ({
        x: index,
        y: d.count,
        name: d.type,
      })),
    });
    series.push(seriesOptions);
    return series;
  }

  /**
   * Calculates the appropriate tick interval for the x-axis based on the date range.
   */
  #getXAxisTickInterval() {
    const chartQueryParams = this.getGraphQueryParams();
    const oneDayInMS = 1000 * 60 * 60 * 24;
    let noOfDays = 0;
    let tickInterval = undefined;
    if (chartQueryParams?.startDate && chartQueryParams?.endDate) {
      noOfDays = Utils.getNoOfDays(chartQueryParams?.startDate, chartQueryParams?.endDate);
      if (noOfDays <= 0) {
        tickInterval = 1000 * 60;
      } else if (noOfDays > 0 && noOfDays <= 1) {
        tickInterval = 1000 * 60 * 60;
      } else if (noOfDays > 1 && noOfDays <= 14) {
        tickInterval = oneDayInMS;
      } else if (noOfDays <= 30 || noOfDays <= 31) {
        tickInterval = this.getChartViewType() === 'sm' ? oneDayInMS * 7 : oneDayInMS;
      } else if ((noOfDays > 30 || noOfDays > 31) && noOfDays < 90) {
        tickInterval = this.getChartViewType() === 'sm' ? oneDayInMS * 14 : oneDayInMS;
      } else if (noOfDays >= 90 && noOfDays <= 365) {
        tickInterval = oneDayInMS * 30;
      } else if (noOfDays > 365) {
        tickInterval = oneDayInMS * 365;
      } else {
        tickInterval = tickInterval;
      }
    }
    return tickInterval;
  }

  /**
   * Retrieves extended options for a date-time chart, including axis configurations and zoom settings.
   */
  #getExtendedDateTimeChartOptions() {
    const chartQueryParams = this.getGraphQueryParams();
    const options = this.#getDateTimeChartOptions();
    options.xAxis.tickInterval = this.#getXAxisTickInterval();
    options.xAxis.min = chartQueryParams?.startDate
      ? new Date(chartQueryParams?.startDate).setHours(0, 0, 0, 0)
      : undefined;
    options.xAxis.max = chartQueryParams?.endDate
      ? new Date(chartQueryParams?.endDate).setHours(23, 59, 59, 999)
      : undefined;
    options.xAxis.tickPositioner = function () {
      this.min = new Date(this.tickPositions[0])?.getTime();
      this.max = new Date(this.tickPositions[this.tickPositions.length - 1])?.getTime();
      return this.tickPositions;
    };
    options.chart.panning.enabled = this.getChartViewType() === 'lg' ? true : false;
    options.chart.zooming.enabled = this.getChartViewType() === 'lg' ? true : false;
    options.chart.zoomType = this.getChartViewType() === 'lg' ? 'x' : 'None';
    options.chart.events.selection = this.#setQueryParamsOnZooming.bind(this);
    return options;
  }
}
