import { Pipe, PipeTransform } from '@angular/core';

export type SortOrder = 'asc' | 'desc';
/**
 * A pipe that sorts an array of items based on specified criteria (number, string, boolean).
 */
@Pipe({
  name: 'sortBy',
  standalone: true,
})
export class SortByPipe implements PipeTransform {
  /**
   * Transforms an array of items by sorting them based on specified criteria.
   *
   * @param value The array of items to be sorted.
   * @param sortOrder The order of sorting ('asc' for ascending, 'desc' for descending). Defaults to 'asc'.
   * @param sortKey Optional. The key in each item to use for sorting (e.g., 'propertyName').
   * @returns The sorted array of items.
   */
  transform(value: any[], sortOrder: SortOrder | string = 'asc', sortKey?: string) {
    sortOrder = sortOrder && (sortOrder.toLowerCase() as string);

    if (!value || (sortOrder !== 'asc' && sortOrder !== 'desc')) return value;

    let numberArray = [];
    let stringArray = [];
    let booleanArray = [];

    if (!sortKey) {
      numberArray = value.filter((item) => typeof item === 'number').sort();
      stringArray = value.filter((item) => typeof item === 'string').sort();
    } else {
      numberArray = value.filter((item) => typeof item[sortKey] === 'number').sort((a, b) => a[sortKey] - b[sortKey]);
      stringArray = value
        .filter((item) => typeof item[sortKey] === 'string')
        .sort((a, b) => {
          if (a[sortKey] < b[sortKey]) return -1;
          else if (a[sortKey] > b[sortKey]) return 1;
          else return 0;
        });
      booleanArray = value.filter((item) => typeof item[sortKey] === 'boolean').sort((a, b) => a[sortKey] - b[sortKey]);
    }
    const sorted = numberArray.concat(stringArray).concat(booleanArray);
    // sorted=sorted
    return sortOrder === 'asc' ? sorted : sorted.reverse();
  }
}
