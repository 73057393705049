<div class="dialog-sm">
  <div class="message-template">
    @if (!fromCreateMessage) {
      <div class="hs-tab-card-title flex-between">
        <h2>{{ 'templates' | translate }}</h2>
        <div class="dialog-header-icons">
          <i-feather name="check" class="pointer" (click)="submitTemplate()"></i-feather>
          <i-feather name="x" class="pointer" mat-dialog-close></i-feather>
        </div>
      </div>
    }
    <mat-dialog-content>
      <!-- <h3>{{ 'templates' | translate }}</h3> -->
      <div class="form-group search-input">
        <input type="text" class="form-control" placeholder="Search" [formControl]="searchQuery" />
        <i-feather name="search" class="feather-20 text-disabled icon-in-input"></i-feather>
      </div>

      <form>
        <div class="template-group">
          <!-- <h6></h6> -->
          @for (template of displayedTemplates; track template) {
            <div class="template-radio">
              <input
                type="radio"
                id="{{ template.id }}"
                name="messageTemplate"
                class="form-check-input"
                value="{{ template.id }}"
                (change)="selectTemplate(template)"
              />
              <label class="radio-label" for="{{ template.id }}">
                <span class="h3">{{ template.title }}</span>
                <span class="template-message ws-pre-wrap">{{ template.message }}</span>
              </label>
            </div>
          }
        </div>
      </form>
    </mat-dialog-content>
  </div>
</div>
