import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { SharedModule } from 'src/app/modules/shared/shared.module';

/**
 * Interface representing a user group list item.
 */
export interface UserGrpList {
  /** Unique identifier for the user group. */
  id: string;
  /** Optional name of the user group. */
  name?: string;
  /** Optional role of the user group. */
  role: string;
  /** Indicates if this is the default user group. */
  defualtUserGrp?: boolean;
  /** Optional value associated with the user group. */
  value?: string;
}

/**
 * Interface representing a user group control.
 */
export interface userGrpControl {
  /** Indicates if this control is default. */
  default?: boolean;
  /** Unique identifier for the user group. */
  id: string;
  /** Optional name of the user group. */
  name?: string;
  /** Role associated with the user group. */
  role: string;
  /** Indicates the checked status of the checkbox. */
  value?: boolean;
}

/**
 * Component for adding and editing user groups.
 */
@Component({
  selector: 'app-add-edit-user-group',
  standalone: true,
  imports: [SharedModule, CommonModule],
  templateUrl: './add-edit-user-group.component.html',
  styleUrl: './add-edit-user-group.component.scss',
})
export class AddEditUserGroupComponent implements OnInit, OnChanges {
  /** List of available user groups. */
  @Input() userGrpList: Array<UserGrpList> = [];
  /** List of selected user groups. */
  @Input() selectedUserGrpList?: Array<UserGrpList> = [];
  /** Type of user. */
  @Input() userType: string = '';
  /** Indicates if the form has been submitted. */
  @Input() submitted = false;
  /** Event emitted when a user group filter is applied. */
  @Output() filterUserGrpKey: EventEmitter<string> = new EventEmitter<string>();
  /** Event emitted when a search is performed. */
  @Output() searchKey: EventEmitter<string | undefined> = new EventEmitter<string | undefined>();

  /** Form control for the search input. */
  search = new FormControl('');
  /** Form control for the select all checkbox. */
  selectAllCheckbox = new FormControl(false);
  /** Form group for the user group edit form. */
  userGrpEditForm!: FormGroup;
  /** Indicates if the select all checkbox is in an indeterminate state. */
  indeterminateSelectAll = false;

  /** Options for user group types */
  userGroupTypes: Array<{ value: string; viewValue: string }> = [
    { value: 'Member', viewValue: 'Member' },
    { value: 'CoOwner', viewValue: 'Co-owner' },
    { value: 'Observer', viewValue: 'Observer' },
    { value: 'owner', viewValue: 'Owner' },
  ];

  /**
   * Creates an instance of AddEditUserGroupComponent.
   * @param fb - FormBuilder instance for managing reactive forms.
   */
  constructor(private fb: FormBuilder) {}

  /**
   * Lifecycle hook that is called when any data-bound property of a directive changes.
   * @param changes - Changes to input properties.
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.userGrpList && changes.userGrpList.previousValue != undefined) {
      this.userGrpList = changes.userGrpList.currentValue;
      this.createUserGrpControls();
    }

    if (changes.userType && changes.userType.previousValue != undefined) {
      this.userType = changes.userType.currentValue;
      this.disableInputs();
    }
  }

  /**
   * Retrieves the user groups form array from the form group.
   * @returns FormArray of user groups.
   */
  get userGroups(): FormArray {
    return this.userGrpEditForm.controls['userGroupListForm'] as FormArray;
  }

  /**
   * Retrieves the selected user groups form array from the form group.
   * @returns FormArray of selected user groups.
   */
  get selectedUserGrp() {
    return this.userGrpEditForm.controls['selectedUserGroupListForm'] as FormArray;
  }

  /**
   * Lifecycle hook that is called after the component has been initialized.
   * Initializes the user group edit form and sets up subscriptions.
   */
  ngOnInit(): void {
    this.userGrpEditForm = this.fb.group({
      userGroupListForm: new FormArray([]),
      selectedUserGroupListForm: new FormArray([]),
    });

    this.createUserGrpControls();

    this.userGroups.valueChanges.subscribe((res: Array<userGrpControl>) => {
      if (res.length == 0) this.selectAllCheckbox.setValue(false);
      if (res.length > 0 && res.every((ele: userGrpControl) => ele.value == true)) {
        this.indeterminateSelectAll = false;
        this.selectAllCheckbox.setValue(true);
      } else if (res.length > 0 && res.every((ele: userGrpControl) => ele.value == false)) {
        this.selectAllCheckbox.setValue(false);
        this.indeterminateSelectAll = false;
      } else if (res.length > 0 && res.some((ele: userGrpControl) => ele.value == true)) {
        this.indeterminateSelectAll = true;
      }
    });

    this.search.valueChanges.pipe(distinctUntilChanged(), debounceTime(1000)).subscribe((res) => {
      if (res !== undefined) {
        this.searchKey.emit(res || '');
      }
    });
  }

  /**
   * Creates form controls for user groups and selected user groups.
   */
  createUserGrpControls() {
    this.userGroups.controls = [];
    this.selectedUserGrp.controls = [];
    this.userGrpList?.forEach((ele) => {
      this.userGroups.push(this.createFormGrp(ele));
    });
    this.selectedUserGrpList?.forEach((ele) => {
      const formGrp = this.createFormGrp(ele);
      if (ele?.role === 'owner') {
        formGrp.get('value')?.disable();
        formGrp.get('role')?.disable();
        formGrp.get('value')?.updateValueAndValidity();
        formGrp.get('role')?.updateValueAndValidity();
      }
      this.selectedUserGrp.push(formGrp);
    });
    this.disableInputs();
  }

  /**
   * Creates a form group for a user group.
   * @param data - User group data to create the form group from.
   * @returns FormGroup representing the user group.
   */
  createFormGrp(data: UserGrpList) {
    return this.fb.group({
      id: new FormControl(data.id),
      name: new FormControl(data.name + (data.defualtUserGrp ? ' (Default Group)' : '')),
      role: new FormControl(data?.role ? data.role : ''),
      value: new FormControl(false),
      default: new FormControl(data.defualtUserGrp),
    });
  }

  /**
   * Selects or deselects all user groups based on the checkbox state.
   * @param eve - The change event from the checkbox.
   */
  selectAll(eve: MatCheckboxChange) {
    this.userGroups.controls.forEach((ele) => {
      ele.get('value')?.setValue(eve.checked);
    });
  }

  /**
   * Emits a filter string for user groups.
   * @param text - The filter string to emit.
   */
  filterUserGrp(text: string) {
    this.filterUserGrpKey.emit(text);
  }

  /**
   * Checks if any user groups are selected in the left list.
   * @returns True if no user groups are selected; otherwise, false.
   */
  anySelectedInLeftList() {
    const filteredControl = this.userGroups.controls.filter((ele) => ele.get('value')?.value === true);
    return filteredControl.length > 0 ? false : true;
  }

  /**
   * Checks if any user groups are selected in the right list.
   * @returns True if no user groups are selected; otherwise, false.
   */
  anySelectedInRightList() {
    const filteredControl = this.selectedUserGrp.controls.filter((ele) => ele.get('value')?.value === true);
    return filteredControl.length > 0 ? false : true;
  }

  /**
   * Moves selected user groups from the left list to the right list.
   */
  moveRight() {
    const filteredControl = this.userGroups.controls.filter((ele) => ele.get('value')?.value === true);
    filteredControl.forEach((ele) => {
      const index = this.userGroups.value.findIndex((element: UserGrpList) => element.id === ele.value.id);
      ele.get('value')?.setValue('');
      ele.get('role')?.setValidators(Validators.required);
      this.selectedUserGrp.updateValueAndValidity();
      this.selectedUserGrp.controls.push(ele);

      this.userGroups.controls.splice(index, 1);
      this.userGroups.updateValueAndValidity();
    });
    if (this.userType !== '') {
      this.disableInputs();
    }
  }

  /**
   * Moves selected user groups from the right list back to the left list.
   */
  moveLeft() {
    const filteredControl = this.selectedUserGrp.controls.filter((ele) => ele.get('value')?.value === true);
    filteredControl.forEach((ele) => {
      const index = this.selectedUserGrp.value.findIndex((element: UserGrpList) => element.id === ele.value.id);
      this.selectedUserGrp.controls.splice(index, 1);
      this.selectedUserGrp.updateValueAndValidity();
      ele.get('value')?.setValue('');
      this.userGroups.controls.push(ele);
    });
  }

  /**
   * Disables input fields based on the user type.
   */
  disableInputs() {
    (<FormArray>this.userGrpEditForm.get('selectedUserGroupListForm')).controls.forEach((control) => {
      if (this.userType.toLowerCase() === 'managed') {
        control.get('role')?.setValue('Member');
        control.get('role')?.disable({ onlySelf: true });
      } else {
        // control.get('role')?.setValue('');
        if (control.get('role')?.value == 'owner') {
          control.get('role')?.disable();
        } else {
          control.get('role')?.enable();
        }
        // control.get('role')?.enable();
        control.get('role')?.setValidators(Validators.required);
      }
      control.updateValueAndValidity();
    });
  }

  /**
   * Returns the selected user groups after form submission.
   * @returns Array of user group controls excluding unnecessary fields.
   */
  returnSelectedUserGrps() {
    this.submitted = true;
    this.selectedUserGrp.enable({ onlySelf: true });
    if (this.selectedUserGrp.valid) {
      const selectedUserGrps = JSON.parse(JSON.stringify(this.selectedUserGrp.value));
      selectedUserGrps.forEach((ele: userGrpControl) => {
        delete ele.name;
        delete ele.value;
        delete ele.default;
      });
      return selectedUserGrps;
    }
  }
}
