import { SafeUrl } from '@angular/platform-browser';
import { MessageAIMetrics } from 'src/app/modules/ai-metrics/entities/ai-metrics.entities';
import { Contact } from 'src/app/modules/shared/interfaces/contact.entities';
import { IntentKeywordData } from './intent-keyword.entities';

/**
 * Represents paginated chat thread metadata.
 */
export interface PaginatedChatThreadMetaData {
  /**
   * Array of chat thread metadata items.
   */
  data: ChatThreadMetaData[];
  /**
   * Pagination details for the chat thread metadata.
   */
  pagination: ChatThreadMetaDataPagination;
}

/**
 * Represents metadata for a chat thread.
 */
export interface ChatThreadMetaData {
  /**
   * Metadata of the last message in the thread.
   */
  lastMessage: LastMessageMetaData;
  /**
   * Topic or subject of the chat thread.
   */
  topic: string;
  /**
   * Number of unread messages in the thread.
   */
  unreadCount: number;
  /**
   * Date and time when the chat thread was created.
   */
  createdAt: string;
  /**
   * Username or identifier of the user who created the thread.
   */
  user: string;
  /**
   * Unique identifier for the chat thread.
   */
  threadId: string;
  /**
   * List of recipients/users involved in the chat thread.
   */
  recipients: UserMetaData[];
  /**
   * Array of IDs of unread messages in the thread.
   */
  unreadMessages: string[];
  /**
   * Initiator or creator of the chat thread.
   */
  initiator: UserMetaData;
  /**
   * Optional flag indicating if the thread requires attention.
   */
  requiresAttention?: boolean;
}

/**
 * Represents pagination metadata for chat thread metadata.
 */
export interface ChatThreadMetaDataPagination {
  /**
   * The current page number of the pagination.
   */
  currentPage: number;
  /**
   * The total number of pages available.
   */
  totalPage: number;
  /**
   * The total count of items across all pages.
   */
  totalCount: number;
  /**
   * The maximum number of items per page.
   */
  limit: number;
  /**
   * The query string used for pagination (optional).
   */
  query: string;
}
/**
 * Represents metadata for a user involved in chat threads.
 */
export interface UserMetaData {
  /**
   * Unique identifier for the communication user.
   */
  communicationUserId: string;
  /**
   * Display name of the user.
   */
  displayName: string;
  /**
   * Username or identifier of the user.
   */
  user: string;
  /**
   * Type of the user, either 'Managed' or 'Manager'.
   */
  userType: 'Managed' | 'Manager';
}
/**
 * Represents metadata for the last message in a chat thread.
 */
export interface LastMessageMetaData {
  /**
   * The content of the last message.
   */
  message: string;
  /**
   * The date and time when the last message was sent.
   */
  date: string;
  /**
   * Username or identifier of the user who sent the last message.
   */
  user: string;
  /**
   * Display name of the user who sent the last message.
   */
  displayName: string;
  /**
   * Unique identifier for the communication user who sent the last message.
   */
  communicationUserId: string;
}
/**
 * Represents metadata associated with a message.
 */
export interface MessageMetaData {
  /**
   * Attachments associated with the message.
   */
  attachments: AttachmentMetaData[];
  /**
   * Date when the message was recalled (if applicable).
   */
  recallDate: string;
  /**
   * Alerts associated with the message (type to be implemented).
   * @type unknown[]
   */
  alerts: unknown[];
  /**
   * Recommended resources related to the message.
   */
  recommendedResources: string[];
  /**
   * Users who have read the message.
   * @type ReadByUser[]
   */
  readBy: ReadByUser[];
  /**
   * Keywords associated with the message.
   * @type Keywords[]
   */
  keyword: IntentKeywordData[];
  /**
   * Intents associated with the message.
   * @type Intent[]
   */
  intent: IntentKeywordData[];
}

/**
 * Represents information about a user who has read a message.
 */
export interface ReadByUser {
  /**
   * The date and time when the message was read by the user.
   * @type string
   */
  date: string;

  /**
   * Username or identifier of the user who read the message.
   * @type string
   */
  user: string;

  /**
   * Unique identifier for the communication user who read the message.
   * @type string
   */
  communicationUserId: string;
}

/**
 * Represents basic information about an attachment.
 */
export interface AttachmentBasicData {
  /**
   * The URL where the attachment is located.
   * @type string
   */
  url: string;

  /**
   * The MIME type of the attachment.
   * @type string
   */
  mimeType: string;
}

/**
 * Represents metadata associated with an attachment, including basic data and additional information.
 */
export interface AttachmentMetaData extends AttachmentBasicData {
  /**
   * Unique identifier for the document.
   * @type string
   */
  documentId: string;

  /**
   * Optional array of user feedback related to the attachment.
   * @type UsersFeedback[]
   */
  usersFeedback?: UsersFeedback[];
}

/**
 * Represents feedback provided by users.
 */
export interface UsersFeedback {
  /**
   * Indicates whether the feedback is marked as favorite.
   * @type boolean
   */
  favorite: boolean;

  /**
   * Indicates whether the feedback is liked.
   * @type boolean
   */
  like: boolean;

  /**
   * Indicates whether the feedback is disliked.
   * @type boolean
   */
  dislike: boolean;

  /**
   * Username or identifier of the user providing the feedback.
   * @type string
   */
  user: string;

  /**
   * Unique identifier for the communication user providing the feedback.
   * @type string
   */
  communicationUserId: string;
}

/**
 * Represents data for a contact dialog.
 */
export interface ContactDialogData {
  /**
   * The header or title for the contact dialog.
   * @type string
   */
  header: string;

  /**
   * The identifier for the thread associated with the contact dialog.
   * @type string
   */
  threadId: string;
}

/**
 * Represents an attachment with additional metadata.
 */
export interface Attachment extends AttachmentMetaData {
  /**
   * Optional file icon associated with the attachment.
   * @type string | SafeUrl
   */
  fileIcon?: string | SafeUrl;

  /**
   * Optional file object representing the attachment.
   * @type File
   */
  file?: File;

  /**
   * Optional upload progress percentage of the attachment.
   * @type number | null
   */
  uploadProgress?: number | null;
}

/**
 * Represents a success response from an API.
 */
export interface APISuccess {
  /**
   * Message describing the success.
   * @type string
   */
  msg: string;
}

/**
 * Represents data for a delete thread dialog.
 */
export interface DeleteThreadDialogData {
  /**
   * The identifier for the thread to be deleted.
   * @type string
   */
  threadId: string;

  /**
   * The header or title for the delete thread dialog.
   * @type string
   */
  header: string;

  /**
   * The message or prompt displayed in the delete thread dialog.
   * @type string
   */
  message: string;

  /**
   * The function to perform when deleting the thread, either 'remove' or 'recall'.
   * @type 'remove' | 'recall'
   */
  function: 'remove' | 'recall';
}

/**
 * Represents data for a recall message dialog, extending from DeleteThreadDialogData.
 */
export interface RecallDialogData extends DeleteThreadDialogData {
  /**
   * The identifier for the message to be recalled.
   * @type string
   */
  messageId: string;

  /**
   * Additional metadata associated with the recall operation.
   * @type Record<string, string>
   */
  metadata: Record<string, string>;
}

/**
 * Represents data for creating a new thread.
 */
export interface CreateThreadData {
  /**
   * Optional message content for the thread.
   * @type string
   */
  message?: string;

  /**
   * Optional ID of the participant involved in the thread.
   * @type string
   */
  participantID?: string;

  /**
   * Specifies the header title type for the thread: 'newMessage', 'forwardMessage', or 'replyMessage'.
   * @type 'newMessage' | 'forwardMessage' | 'replyMessage'
   */
  headerTitle: 'newMessage' | 'forwardMessage' | 'replyMessage';

  /**
   * Optional ID of the alert associated with the thread.
   * @type string
   */
  alertId?: string;

  /**
   * Boolean indicating whether to initialize ACS (Advanced Communication Services).
   * @type boolean
   */
  initACS: boolean;
}

/**
 * Represents data for previewing attachments within a thread or message.
 */
export interface AttachmentPreviewData {
  /**
   * The selected attachment for preview.
   * @type Attachment
   */
  selectedAttachment: Attachment;

  /**
   * List of attachments associated with the thread or message.
   * @type Attachment[]
   */
  attachmentList: Attachment[];

  /**
   * The identifier for the thread associated with the attachments.
   * @type string
   */
  threadId: string;

  /**
   * The identifier for the message associated with the attachments.
   * @type string
   */
  messageId: string;
}

/**
 * Represents a temporary URL pointing to a file.
 */
export interface TemporaryFileUrl {
  /**
   * The URL of the temporary file.
   * @type string
   */
  url: string;
}

/**
 * Represents a contact who has read a message, including the date of reading.
 */
export interface ReadByContactWithDate extends Contact {
  /**
   * The date when the message was read by the contact.
   * @type string
   */
  date: string;
}

/**
 * Represents data related to a favorite attachment.
 */
export interface AttachmentFavoriteData {
  /**
   * The identifier for the thread associated with the attachment.
   * @type string
   */
  threadId: string;

  /**
   * The identifier for the message associated with the attachment.
   * @type string
   */
  messageId: string;

  /**
   * The URL of the favorite attachment.
   * @type string
   */
  url: string;
}

/**
 * Represents success data for multiple file uploads.
 */
export interface MultipleFileUploadSuccess {
  /**
   * Array of attachment metadata containing URLs of successfully uploaded files.
   * @type AttachmentMetaData[]
   */
  urls: AttachmentMetaData[];
}

/**
 * Represents filters for querying chat messages.
 */
export interface ChatFilter {
  /**
   * Subject keyword for filtering messages.
   * @type string
   */
  subject: string;

  /**
   * Message content keyword for filtering messages.
   * @type string
   */
  message: string;

  /**
   * Flag indicating if messages should be unread.
   * @type boolean
   */
  unread: boolean;

  /**
   * Start date for filtering messages.
   * @type Date | null
   */
  startDate: Date | null;

  /**
   * End date for filtering messages.
   * @type Date | null
   */
  endDate: Date | null;

  /**
   * Optional user identifier for filtering messages.
   * @type string | undefined
   */
  user?: string;

  /**
   * Contact to filter messages addressed to.
   * @type Contact | null
   */
  to: Contact | null;

  /**
   * Contact to filter messages sent from.
   * @type Contact | null
   */
  from: Contact | null;

  /**
   * Page number for paginating results.
   * @type number
   */
  page: number;

  /**
   * Flag indicating if messages should be unhandled.
   * @type boolean
   */
  unhandled: boolean;
}

/**
 * Represents data for a recalled message.
 */
export interface RecalledMessageData {
  /**
   * The date and time when the message was recalled.
   * @type string
   */
  recalledAt: string;

  /**
   * The user who initiated the message recall.
   * @type string
   */
  recalledBy: string;

  /**
   * The content of the recalled message.
   * @type string
   */
  message: string;

  /**
   * The identifier of the recalled message.
   * @type string
   */
  messageId: string;

  /**
   * The user associated with the recalled message.
   * @type string
   */
  user: string;

  /**
   * The date when the message was originally sent.
   * @type Date
   */
  sentDate: Date;

  /**
   * Array of attachment basic data associated with the recalled message.
   * @type AttachmentBasicData[]
   */
  attachments: AttachmentBasicData[];
}

/**
 * Represents a message template with predefined content and metadata.
 */
export interface MessageTemplate {
  /**
   * The unique identifier of the message template.
   * @type string
   */
  id: string;

  /**
   * The title or name of the message template.
   * @type string
   */
  title: string;

  /**
   * The subject line of the message template.
   * @type string
   */
  subject: string;

  /**
   * An array of tags associated with the message template for categorization.
   * @type string[]
   */
  tags: string[];

  /**
   * The main content or body of the message template.
   * @type string
   */
  message: string;

  /**
   * An array of quick replies or responses associated with the message template.
   * @type string[]
   */
  quickReplies: string[];
}

/**
 * Enum representing keys used for placeholders in message templates.
 */
export enum MessageTemplateReplacementKeys {
  /**
   * Placeholder for the sender's first name.
   */
  SENDERFIRSTNAME = ':senderFname',

  /**
   * Placeholder for the sender's last name.
   */
  SENDERLASTNAME = ':senderLname',

  /**
   * Placeholder for the receiver's first name.
   */
  RECEIVERFIRSTNAME = ':receiverFname',

  /**
   * Placeholder for the receiver's last name.
   */
  RECEIVERLASTNAME = ':receiverLname',

  /**
   * Placeholder for the patient's first name.
   */
  PATIENTFIRSTNAME = ':patientFname',

  /**
   * Placeholder for the patient's first name.
   */
  WORKPHONE = ':workPhone',
}

/**
 * Represents recommended resources with a summary and a list of resource URLs or titles.
 */
export interface RecommendedResources {
  /**
   * A summary or description of the recommended resources.
   * @type string
   */
  summary: string;

  /**
   * An array of strings representing resource URLs or titles.
   * @type string[]
   */
  resources: string[];
}

/**
 * Represents detailed data about a resource.
 */
export interface ResourceData {
  /**
   * Indicates if the resource is liked by the user.
   * @type boolean
   */
  like: boolean;

  /**
   * Indicates if the resource is disliked by the user.
   * @type boolean
   */
  dislike: boolean;

  /**
   * Indicates if the resource is marked as favorite by the user.
   * @type boolean
   */
  favorite: boolean;

  /**
   * The unique identifier of the resource.
   * @type string
   */
  id: string;

  /**
   * Indicates if the resource is system-generated.
   * @type boolean
   */
  system: boolean;

  /**
   * The type of the resource.
   * @type string
   */
  type: string;

  /**
   * Indicates if the resource is supported.
   * @type boolean
   */
  supported: boolean;

  /**
   * Detailed information about the resource.
   */
  resource: {
    /**
     * The type of content the resource represents.
     * @type 'Url' | 'FileUpload' | 'HealthWise'
     */
    contentType: 'Url' | 'FileUpload' | 'HealthWise';

    /**
     * Indicates if respondents are tracked for the resource.
     * @type boolean
     */
    trackRespondents: boolean;

    /**
     * Indicates if the resource includes recommended resources.
     * @type boolean
     */
    includeRecommendedResource: boolean;

    /**
     * The MIME type of the resource.
     * @type string
     */
    mimetype: string;

    /**
     * The URL of the resource's image.
     * @type string
     */
    imageUrl: string;

    /**
     * The unique identifier of the topic associated with the resource.
     * @type string
     */
    topicId: string;

    /**
     * The caption or title of the resource.
     * @type string
     */
    caption: string;

    /**
     * A description of the resource.
     * @type string
     */
    description: string;

    /**
     * The content URL of the resource.
     * @type string
     */
    contentUrl: string;

    /**
     * The unique identifier of the resource.
     * @type string
     */
    id: string;
  };

  /**
   * Reserved for future use; currently set to null.
   * @type null
   */
  parent: null;
}

/**
 * Represents a suggested message for automated replies or suggestions.
 */
export interface SuggestedMessage {
  /**
   * The suggested reply message.
   * @type string
   */
  reply: string;
  /**
   * AI Metrics
   * @type MessageAIMetrics
   */
  metrics?: MessageAIMetrics[];
  /**
   * Optional stream URL associated with the suggested message.
   * @type string | undefined
   */
  streamUrl?: string;
}

/**
 * Represents input data for fetching a list of messages.
 */
export interface MessageListInputData {
  /**
   * Optional patient ID associated with the messages.
   * @type string | undefined
   */
  patientId?: string;

  /**
   * Optional flag indicating if the messages are unhandled.
   * @type boolean | undefined
   */
  unhandled?: boolean;

  /**
   * Optional chat thread metadata object.
   * @type ChatThreadMetaData | undefined
   */
  thread?: ChatThreadMetaData;
}

/**
 * Represents data for configuring thread filters in a dialog.
 */
export interface ThreadFilterDialogData {
  /**
   * The chat filter configuration for the thread.
   * @type ChatFilter
   */
  chatThreadFilter: ChatFilter;

  /**
   * The mode of filtering messages, either 'MyMessages' or 'MyTeamMessages'.
   * @type 'MyMessages' | 'MyTeamMessages'
   */
  mode: 'MyMessages' | 'MyTeamMessages';
}
