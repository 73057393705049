import { CdkMenuModule } from '@angular/cdk/menu';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FeatherModule } from 'angular-feather';
import { allIcons } from 'angular-feather/icons';
import { HighchartsChartModule } from 'highcharts-angular';
import { PermissionsModule } from '../permissions/permissions.module';
import { SupportIconComponent } from '../support/components/support-icon/support-icon.component';
import { CameraViewerComponent } from './components/camera-viewer/camera-viewer.component';
import { DobComponent } from './components/dob/dob.component';
import { FilterComponent } from './components/filter/filter.component';
import { ContactsComponent } from './components/header/header-dialogs/contacts/contacts.component';
import { HeaderFilterComponent } from './components/header/header-dialogs/header-filter/header-filter.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { TableComponent } from './components/table/table.component';
import { ClientLabelPipe } from './pipes/client-label.pipe';
import { FilterPopUpPipe } from './pipes/filterPopUp.pipe';
import { TranslatePipe } from './pipes/translate.pipe';
import { UserGrpTypePipe } from './pipes/user-grp-type.pipe';

/**
 * Module that selects a subset of Feather icons from FeatherModule.
 */
const matModules = [FeatherModule.pick(allIcons)];
@NgModule({
  declarations: [
    HeaderFilterComponent,
    ContactsComponent,
    CameraViewerComponent,
    TableComponent,
    DobComponent,
    PaginationComponent,
    FilterComponent,
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    matModules,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    HighchartsChartModule,
    MatSlideToggleModule,
    MatExpansionModule,
    MatTabsModule,
    MatAutocompleteModule,
    MatChipsModule,
    CdkMenuModule,
    CommonModule,
    NgbModule,
    PermissionsModule,
    MatIconModule,
    MatStepperModule,
    MatTableModule,
    MatPaginatorModule,
    MatSliderModule,
    MatCheckboxModule,
    MatSortModule,
    MatProgressSpinnerModule,
    UserGrpTypePipe,
    TranslatePipe,
    ClientLabelPipe,
    FilterPopUpPipe,
    SupportIconComponent,
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    matModules,
    MatDialogModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    HighchartsChartModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatAutocompleteModule,
    MatChipsModule,
    NgbModule,
    MatPaginatorModule,
    PermissionsModule,
    MatIconModule,
    RouterModule,
    MatStepperModule,
    MatTableModule,
    MatSliderModule,
    MatExpansionModule,
    TableComponent,
    DobComponent,
    PaginationComponent,
    MatCheckboxModule,
    MatSortModule,
    FilterComponent,
    UserGrpTypePipe,
    TranslatePipe,
    ClientLabelPipe,
    SupportIconComponent,
  ],
})
export class SharedModule {}
