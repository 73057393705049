import { Injectable, signal, WritableSignal } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom, forkJoin, map } from 'rxjs';
import { HttpService } from 'src/app/modules/shared/services/http.service';
import {
  AddComment,
  MapUserZendeskPostO,
  MyTicket,
  NewCommentByAgent,
  UploadAttachment,
  zendeskTicketFormData,
  zendeskTicketPostData,
} from 'src/app/modules/support/entities/support-entities';
import { environment } from 'src/environments/environment';
import { AuthServiceService } from '../authentication/services/auth-service.service';
import { MessageService } from '../message/services/message.service';
/**
 * Service for interacting with Zendesk API.
 */
@Injectable({
  providedIn: 'root',
})
export class SupportService {
  /**
   * Signal for showing active MyTickets tabs.
   */
  selectedMyTicketTab: WritableSignal<string> = signal('all');
  /**
   * Signal for unread tickets.
   */
  unreadTickets: WritableSignal<MyTicket[]> = signal([]);
  /**
   * Constant value indicating a new comment made by an agent.
   * @type {string}
   */
  new_comment_by_agent: string = NewCommentByAgent.new_comment_by_agent;
  /**
   * Maximum allowed file size for attachments, in bytes.
   */
  private maxFileSizeAllowed = 27262976;

  /**
   * Maximum number of files allowed to be attached.
   */
  private maxNumberOfFilesAllowed = 10;

  /**
   * Base URL for the Zendesk API.
   */
  private baseUrl: string = environment.url.apiHost;

  /**
   * API version used for Zendesk API requests.
   */
  private apiVersion: string = environment.url.version;

  /**
   * Active user's ID in Zendesk.
   */
  private zendeskActiveUserId = '';

  /**
   * Active user's name in Zendesk.
   */
  private zendeskActiveUserName = '';

  /**
   * Constructs an instance of SupportService.
   * @param httpService - HttpService instance for making HTTP requests.
   * @param authService - AuthServiceService instance for authentication.
   * @param messageService - MessageService instance for displaying messages.
   * @param toastrService - ToastrService instance for displaying toast notifications.
   */
  constructor(
    private httpService: HttpService,
    private authService: AuthServiceService,
    private messageService: MessageService,
    private toastrService: ToastrService,
  ) {}

  /**
   * Maps a user to Zendesk.
   * @param body The user data to be mapped.
   * @returns A promise that resolves with the API response.
   */
  public async mapUser(body: MapUserZendeskPostO) {
    const url = `${this.baseUrl}${this.apiVersion}/integration/zendesk`;
    return firstValueFrom(
      this.httpService.post(url, { endPoint: '/users/create_or_update.json', method: 'POST', data: body }),
    );
  }

  /**
   * Retrieves all tickets from Zendesk.
   * @returns A promise that resolves with the API response.
   */
  public getAllTickets() {
    const url = `${this.baseUrl}${this.apiVersion}/integration/zendesk`;
    return this.httpService.post(url, { endPoint: '/tickets.json', method: 'GET' });
  }
  /**
   * Retrieves tickets requested by a specific user.
   * @param userId The ID of the user whose tickets are to be retrieved.
   * @returns A promise that resolves with the API response.
   */
  public getTicketsByExternalId(externalId: string | null) {
    const url = `${this.baseUrl}${this.apiVersion}/integration/zendesk`;
    return this.httpService.post(url, {
      endPoint: `/tickets.json${externalId ? `?external_id=${externalId}` : ``}`,
      method: 'GET',
    });
  }
  /**
   * Retrieves tickets requested by a specific user.
   * @param userId The ID of the user whose tickets are to be retrieved.
   * @returns A promise that resolves with the API response.
   */
  public getMyTickets(userId: string | null) {
    const url = `${this.baseUrl}${this.apiVersion}/integration/zendesk`;
    return this.httpService.post(url, {
      endPoint: `/users/${userId}/tickets/requested.json`,
      method: 'GET',
    });
  }

  /**
   * Retrieves details of a ticket by its ID.
   * @param ticketId The ID of the ticket to retrieve details for.
   * @returns A promise that resolves with the API response.
   */
  public async getTicketDetailsById(ticketId: number) {
    const url = `${this.baseUrl}${this.apiVersion}/integration/zendesk`;
    return firstValueFrom(
      this.httpService.post(url, {
        endPoint: `/tickets/${ticketId}.json`,
        method: 'GET',
      }),
    );
  }

  /**
   * Retrieves comments for a ticket by its ID.
   * @param ticketId The ID of the ticket to retrieve comments for.
   * @returns A promise that resolves with the API response.
   */
  public async getCommentsTicketById(ticketId: number) {
    const url = `${this.baseUrl}${this.apiVersion}/integration/zendesk`;
    return firstValueFrom(
      this.httpService.post(url, {
        endPoint: `/tickets/${ticketId}/comments.json`,
        method: 'GET',
      }),
    );
  }

  /**
   * Retrieves multiple users by their IDs.
   * @param userIds Array of user IDs to retrieve.
   * @returns A promise that resolves with the API response.
   */
  public getUsersByIds(userIds: number[]) {
    const url = `${this.baseUrl}${this.apiVersion}/integration/zendesk`;
    return this.httpService.post(url, {
      endPoint: `/users/show_many.json?ids=${userIds}`,
      method: 'GET',
    });
  }

  /**
   * Creates a new ticket in Zendesk.
   * @param postO Data for creating the ticket.
   * @returns A promise that resolves with the API response.
   */
  public create(postO: zendeskTicketPostData) {
    const url = `${this.baseUrl}${this.apiVersion}/integration/zendesk`;
    return this.httpService.post(url, {
      endPoint: `/tickets.json`,
      method: 'POST',
      data: postO,
    });
  }

  /**
   * Uploads a file to Zendesk.
   * @param file The file to upload.
   * @returns A promise that resolves with the API response.
   */
  public uploadFile(file: File) {
    const url = `${this.baseUrl}${this.apiVersion}/integration/zendesk`;
    return this.httpService.post(url, {
      endPoint: `/uploads.json?filename=${file.name}`,
      method: 'POST',
      data: file,
    });
  }

  /**
   * Adds a comment to a ticket in Zendesk.
   * @param ticketId The ID of the ticket to add the comment to.
   * @param postO Data for adding the comment.
   * @returns A promise that resolves with the API response.
   */
  public addComment(ticketId: number, postO: AddComment) {
    const url = `${this.baseUrl}${this.apiVersion}/integration/zendesk`;
    return this.httpService.post(url, {
      endPoint: `/tickets/${ticketId}.json`,
      method: 'PUT',
      data: postO,
    });
  }

  /**
   * Removes a new update tag from a ticket in Zendesk.
   * @param ticketId The ID of the ticket to remove the tag from.
   * @param postO Data for removing the tag.
   * @returns A promise that resolves with the API response.
   */
  public removeNewUpdateTag(ticketId: number, postO: { ticket: { remove_tags: (string | object)[] } }) {
    const url = `${this.baseUrl}${this.apiVersion}/integration/zendesk`;
    return this.httpService.post(url, {
      endPoint: `/tickets/update_many.json?ids=${ticketId}`,
      method: 'PUT',
      data: postO,
    });
  }

  /**
   * Uploads multiple files to Zendesk.
   * @param attachments Array of files to upload.
   * @returns A promise that resolves with the API response.
   */
  public async uploadFiles(attachments: UploadAttachment[]) {
    const uploadFilesObservable = [];
    for (let i = 0; i < attachments.length; i++) {
      uploadFilesObservable.push(this.uploadFile(attachments[i].file).pipe(map((response) => response.upload.token)));
    }
    return firstValueFrom(forkJoin(uploadFilesObservable));
  }

  /**
   * Previews a file and validates its size and type before adding it to attachments.
   * @param file The file to preview.
   * @param attachments Array to store uploaded attachments.
   */
  public preview(file: File, attachments: UploadAttachment[]): void {
    if (file) {
      if (attachments.length >= this.maxNumberOfFilesAllowed) {
        this.toastrService.error('Only 10 files are allowed per message.', `Error File - ${file.name}`);
        return;
      }
      if (file.size > this.maxFileSizeAllowed) {
        this.toastrService.error('Maximum 25MB allowed per file', `Error File - ${file.name}`);
        return;
      }
      const localFileUrl: string = URL.createObjectURL(file);
      const fileIcon: string | SafeUrl | null = this.getFileIcon(file.type, localFileUrl);
      if (!fileIcon) {
        this.toastrService.error('File format not supported', `Error File - ${file.name}`);
        return;
      }
      attachments.push({ file: file, icon: fileIcon });
    }
  }
  /**
   * Sets the active Zendesk user's name.
   * @param name The name of the active user.
   */
  setZendeskActiveUserName(name: string) {
    if (name) {
      this.zendeskActiveUserName = name;
    }
  }

  /**
   * Sets the active Zendesk user's ID.
   * @param userId The ID of the active user.
   */
  setZendeskActiveUserId(userId: string) {
    if (userId) {
      this.zendeskActiveUserId = userId;
    }
  }

  /**
   * Retrieves the active Zendesk user's ID.
   * @returns The ID of the active user, or null if not set.
   */
  getZendeskActiveUserId() {
    return this.zendeskActiveUserId ? this.zendeskActiveUserId : null;
  }

  /**
   * Retrieves the active Zendesk user's name.
   * @returns The name of the active user, or null if not set.
   */
  getZendeskActiveUserName() {
    return this.zendeskActiveUserName ? this.zendeskActiveUserName : null;
  }

  /**
   * Retrieves the client name from authentication service.
   * @returns The client name.
   */
  getClientName() {
    return this.authService.getClientName();
  }
  /**
   * Retrieves the userId from authentication service.
   * @returns The user id.
   */
  getUsertId() {
    return this.authService.getUserId() || null;
  }
  /**
   * Retrieves the file icon based on its type and URL.
   * @param fileType The type of the file.
   * @param fileUrl The URL of the file.
   * @returns The file icon URL or SafeUrl.
   */
  getFileIcon(fileType: string, fileUrl: string): string | SafeUrl | null {
    return this.messageService.getFileIcon(fileType, fileUrl);
  }
  /**
   * Retrieves the key or status associated with unread comments by an agent.
   * This method is typically used to check or fetch the status of unread comments.
   */
  getUnreadStatusTagKey() {
    return this.new_comment_by_agent;
  }
  /**
   * Retrieves a filtered list of unread tickets that contain the 'new_comment_by_agent' tag.
   * This method checks each ticket's `tags` property to see if it includes the specific tag
   * related to new comments made by the agent.
   */
  getUnreadTickets() {
    return this.unreadTickets()?.filter((ticket: MyTicket) =>
      ticket?.tags?.includes(NewCommentByAgent.new_comment_by_agent),
    );
  }
  /**
   * Removes the unread status tag from a specific ticket identified by its `id`.
   * This method searches for the ticket by its `id`, checks if the ticket contains the
   * unread status tag (determined by `getUnreadStatusTagKey()`), and removes the tag if present.
   *
   * @param {string | number | null} id The ID of the ticket to remove the unread status tag from.
   */
  removeUnreadTagByTicketId(id: string | number | null) {
    if (id) {
      this.unreadTickets.update((tickets) => {
        const ticket = tickets?.find((ticket: MyTicket) => ticket.id === id) || null;
        if (ticket) {
          const unreadTagIndex = ticket?.tags?.findIndex((tag) => tag === this.getUnreadStatusTagKey());
          if (unreadTagIndex >= 0) {
            tickets?.find((t) => t.id === id)?.tags?.splice(unreadTagIndex, 1);
          }
        }
        return [...tickets];
      });
    }
  }
  /**
   * Callback function executed when a file input changes.
   * @param ev The change event.
   * @param attachments Array to store uploaded attachments.
   */
  onChangeFileCallBack(ev: Event, attachments: UploadAttachment[]): void {
    const files: FileList | null = (ev.target as HTMLInputElement)?.files;
    if (files && files.length) {
      for (let i = 0; i < files.length; i++) {
        this.preview(files[i], attachments);
      }
      (ev.target as HTMLInputElement).value = '';
    }
  }

  /**
   * Constructs data for creating a Zendesk ticket.
   * @param zendeskData Data for creating the Zendesk ticket.
   * @returns The constructed data object for creating the ticket.
   */
  getZendeskTicketPostO(zendeskData: zendeskTicketFormData) {
    /**
     * Retrieve active Zendesk user ID
     */
    const zendeskActiveUserId = this.getZendeskActiveUserId();
    /**
     * Retrieve client name
     */
    const clientName = this.getClientName();
    const postO = {
      ticket: {
        status: 'open',
        subject: zendeskData?.titleKey === 'feedback' ? 'Feedback: ' + zendeskData.subject : zendeskData.subject,
        comment: { body: zendeskData.body, uploads: zendeskData.fileTokens },
        requester_id: zendeskActiveUserId,
        submitter_id: zendeskActiveUserId,
        external_id: this.authService.getUserId() || null,
        type: 'problem',
        via: { channel: 'web' },
        custom_fields: [
          { id: '24398743', value: 'web_doctor' },
          { id: '360017433452', value: clientName },
          { id: '25306206', value: environment.env },
        ],
      },
    };
    if (!zendeskData?.fileTokens?.length) {
      delete postO.ticket.comment.uploads;
    }
    return postO;
  }
}
