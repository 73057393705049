<div class="time-period-btn">
  @for (button of timePeriodButtons(); track button) {
    <button
      class="btn"
      [class]="button.value === selectedTimePeriod() ? 'btn-primary' : 'btn-outline-primary'"
      (click)="loadSummaryBasedOnTimePeriod(button.value)"
    >
      {{ button.label }}
    </button>
  }
</div>
<div class="patient-summary-container">
  <div class="patient-summary-item">
    @if (responseStatus() === 'Loading') {
      <app-skeleton-loader></app-skeleton-loader>
    }

    <div class="slide-down-base" [ngClass]="{ 'slide-down-animation': responseStatus() !== 'Loading' }">
      <div class="patient-summary-inner ws-pre-line">
        @if (responseStatus() === 'No Summary') {
          <div class="no-patient-summary-message">
            {{ 'noPatientSummaryMessage' | translate }}
          </div>
        }

        @if (creationMessage() && responseStatus() !== 'No Summary') {
          <div class="patient-summary-creation-message">
            {{ creationMessage() }}
          </div>
        }

        @if (responseStatus() === 'Summary Received') {
          @for (details of summary; track $index) {
            <div class="patient-summary-section">
              <h4>
                {{ details.header }}
              </h4>
              <p>
                {{ details.summary }}
              </p>
            </div>
          }
        }

        @if (responseStatus() === 'Stream') {
          <div class="patient-summary-section">
            <h4>{{ 'summaryOfGuidedSession' | translate }}</h4>
            <p>{{ healthTrackerSummary() }}</p>
            @if (!streaming().includes('1')) {
              <app-dot-loader></app-dot-loader>
            }
          </div>
          <div class="patient-summary-section">
            <h4>{{ 'summaryOfMessages' | translate }}</h4>
            <p>{{ messageSummary() }}</p>
            @if (!streaming().includes('2')) {
              <app-dot-loader></app-dot-loader>
            }
          </div>
          <div class="patient-summary-section">
            <h4>{{ 'summaryOfVitalSigns' | translate }}</h4>
            <p>{{ vitalNonStreamingSummary() }}</p>
            @if (!streaming().includes('3')) {
              <app-dot-loader></app-dot-loader>
            }
          </div>
          <div class="patient-summary-section">
            <h4>{{ 'streamingDataSummary' | translate }}</h4>
            <p>{{ vitalStreamingSummary() }}</p>
            @if (!streaming().includes('4')) {
              <app-dot-loader></app-dot-loader>
            }
          </div>
        }
      </div>

      @if (aIMetricsState() === 'Fetching') {
        <div class="ai-metrics-fetching">
          <app-skeleton-loader [lines]="1"></app-skeleton-loader>
        </div>
      }

      @if (aIMetricsState() === 'Fetched') {
        <div class="message-matrix">
          <div class="matrix-wrap">
            @for (metric of aiMetricsFormattedData(); track $index) {
              @if (metric.labelKey !== 'tableInfo') {
                <span>
                  {{ metric.labelKey | translate }}:
                  <strong>{{ metric.score === -1 ? '–' : metric.score + '%' }}</strong>
                </span>
              }
            }
          </div>
          <i-feather name="info" class="pointer feather-20 p-0" (click)="openSummaryDetailAnalysis()"></i-feather>
        </div>
      }

      @if (feedbackMode() !== 'No Feedback') {
        <div class="summary-feedback">
          <h3>{{ 'patientSummaryFeedbackQuestion' | translate }}</h3>
          @if (feedbackMode() === 'Helpful') {
            <div class="btn-group">
              <button class="btn btn-primary" (click)="helpful = true; feedbackMode.set('Comment')">
                {{ 'yes' | translate }}
              </button>
              <button class="btn btn-primary" (click)="helpful = false; feedbackMode.set('Comment')">
                {{ 'no' | translate }}
              </button>
            </div>
          }
          @if (feedbackMode() === 'Comment') {
            <div>
              <label>{{ 'comments' | translate }} {{ 'optional' | translate }}</label>
              <textarea class="form-control" rows="6" [(ngModel)]="feedback"></textarea>
              <button class="btn btn-primary submit-button" (click)="sendFeedback()">{{ 'Submit' | translate }}</button>
            </div>
          }
        </div>
      }

      @if (thankYouMessage) {
        <div class="summary-feedback">
          <p class="mb-0">{{ thankYouMessage }}</p>
        </div>
      }
    </div>
  </div>
</div>
